import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import axios from './AxiosInstance';
import AsyncSelect2 from './AsyncSelect2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faPlus, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Tabs, Tab } from 'react-bootstrap';
import '../assets/styles/App.css';
import ReactInputMask from 'react-input-mask';
import ConfirmationCnpj from './ConfirmationCnpj';
import InfoPopover from './InfoPopover';
import { CSSTransition } from 'react-transition-group';

function CustomerEdit() {
    const { setAlert, axiosConfigured } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        customerType: 'PESSOA_JURIDICA',
        name: '',
        legalName: '',
        cnpj: '',
        cpf: '',
        stateRegistration: '',
        cityRegistration: '',
        phone: '',
        cellPhone: '',
        email: '',
        website: '',
        active: true,
        simplesNacional: false,
        endConsumer: false,
        icmsTaxPayerIndicator: ''
    });
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [activeTab, setActiveTab] = useState('general');
    const [showConfirmCnpj, setShowConfirmCnpj] = useState(false);
    const [cnpjToConfirm, setCnpjToConfirm] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const { customer: initialCustomer, searchTerm, page, rows, sortField, sortOrder } = location.state || {};
    const [isFormChanged, setIsFormChanged] = useState(false);
    const hasFetchedData = useRef(false);
    const [customer, setCustomer] = useState(initialCustomer || {});
    const [inProp, setInProp] = useState(false);
    const loadingTimeout = useRef(null);
    const lastValidatedCnpj = useRef('');
    const [addresses, setAddresses] = useState(customer.addresses || []);
    const [editingAddress, setEditingAddress] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const initializeForm = useCallback((customer) => {
        setCustomer(customer);
        
        const newAddress = {
            id: Date.now(),
            description: 'Principal',
            street: customer.street || '',
            number: customer.number || '',
            complement: customer.complement || '',
            neighborhood: customer.neighborhood || '',
            zipCode: customer.zipCode || '',
            city: customer.city ? {
                id: customer.city.id,
                name: customer.city.name,
                uf: customer.city.uf
            } : null,
            billingAddress: true,
            shippingAddress: true
        };

        const addresses = customer.addresses || [];

        if (newAddress.street || newAddress.number || newAddress.neighborhood || newAddress.zipCode) {
            addresses.push(newAddress);
        }

        setAddresses(addresses);

        setFormData({
            customerType: customer.customerType || 'PESSOA_JURIDICA',
            name: customer.name || '',
            legalName: customer.legalName || '',
            cnpj: customer.cnpj || '',
            cpf: customer.cpf || '',
            stateRegistration: customer.stateRegistration || '',
            cityRegistration: customer.cityRegistration || '',
            phone: customer.phone || '',
            cellPhone: customer.cellPhone || '',
            email: customer.email || '',
            website: customer.website || '',
            active: customer.active || 'true',
            simplesNacional: customer.isSimples? customer.isSimples: customer.simplesNacional || 'false',
            endConsumer: customer.endConsumer || 'false',
            icmsTaxPayerIndicator: extractCodeFromDescription(customer.icmsTaxPayerIndicator) || ''
        });
    }, []);

    const loadCustomer = useCallback(async () => {
        try {
            const response = await axios.get(`/api/customers/${id}`);
            clearTimeout(loadingTimeout.current);
            initializeForm(response.data);
            setShowForm(true);
        } catch (error) {
            console.error('Erro ao carregar cliente:', error);
            if (error.response && error.response.status === 404) {
                navigate('/customers', { state: { searchTerm, page, rows, sortField, sortOrder } });
            }
        } finally {
            setLoading(false);
        }
    }, [id, navigate, initializeForm, page, rows, searchTerm, sortField, sortOrder]);

    useEffect(() => {
        if (axiosConfigured && !hasFetchedData.current && id !== 'new') {
            loadingTimeout.current = setTimeout(() => {
                setLoading(true);
            }, 500);
            loadCustomer();
            hasFetchedData.current = true;
        } else if (id === 'new') {
            clearTimeout(loadingTimeout.current);
            setLoading(false);
            setFormData((prevFormData) => ({
                ...prevFormData,
                customerType: 'PESSOA_JURIDICA'
            }));
            setShowForm(true);
        }
    }, [axiosConfigured, id, loadCustomer]);

    useEffect(() => {
        setInProp(true);
        if (activeTab !== 'address') {
            setIsEditing(false);
            setEditingAddress(null);
        }
    }, [activeTab]);

    const handleCnpj = useCallback(async (cnpj) => {
        try {
            const response = await axios.get(`/api/cnpj/${cnpj}`);
            if (response.data) {
                confirmCnpj(response.data);
                setIsFormChanged(true);
            } else {
                response.data.cnpj = cnpj;
            }
            if (id !== "new") {
                response.data.id = id;
            }
        } catch (error) {
            //console.info("Não foi possível consultar o CNPJ");
        }
    }, [id]);

    const extractCodeFromDescription = (description) => {
        if (description) {
            const codeMatch = description.match(/^\d+/);
            return codeMatch ? codeMatch[0] : '';
        }
        return '';
    };

    const isAddressValid = () => {
        return editingAddress &&
            editingAddress.description?.trim() !== '' &&
            editingAddress.street?.trim() !== '' &&
            editingAddress.number?.trim() !== '' &&
            editingAddress.neighborhood?.trim() !== '' &&
            editingAddress.zipCode?.trim() !== '' &&
            editingAddress.city;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let parsedValue = value;

        if (name === 'stateRegistration' && value.length > 14) {
            return;
        }
    
        if (name === 'simplesNacional' || name === 'endConsumer' || name === 'active') {
            parsedValue = value === 'true';
        }

        setFormData((prevData) => {
            const isChanged = prevData[name] !== parsedValue;

            if (name === 'customerType' && isChanged) {
                setIsFormChanged(true);
                return {
                    ...prevData,
                    customerType: parsedValue,
                    cnpj: '',
                    cpf: '',
                    legalName: '',
                    name: ''
                };
            }
    
            if (name === "cnpj" && isChanged) {
                const cnpj = removeFormatting(value);
                if (cnpj.length === 14 && cnpj !== lastValidatedCnpj.current) {
                    lastValidatedCnpj.current = cnpj;
                    handleCnpj(cnpj);
                }
            }
    
            if (isChanged) {
                setIsFormChanged(true);
            }
    
            return { ...prevData, [name]: parsedValue };
        });
    };

    const confirmCnpj = (cnpjToConfirm) => {
        setCnpjToConfirm(cnpjToConfirm);
        setShowConfirmCnpj(true);
    };

    const handleSelectCnpj = async (customer) => {
        customer.phone = customer.phone1;
        initializeForm(customer);
        setShowConfirmCnpj(false);
        setIsFormChanged(true);

    };

    const handleAddressInputChange = (e) => {
        const { name, value } = e.target;
        setEditingAddress((prevAddress) => ({
            ...prevAddress,
            [name]: value,
        }));
    };
    
    const handleAddNewAddress = () => {
        setEditingAddress({
            description: '',
            street: '',
            number: '',
            complement: '',
            neighborhood: '',
            zipCode: '',
            city: null,
            billingAddress: false,
            shippingAddress: false
        });
        setIsEditing(true);
    };
    
    const handleSaveAddress = () => {
        if (editingAddress && editingAddress.street && editingAddress.number && editingAddress.neighborhood && editingAddress.city) {
            if (editingAddress.id) {
                setAddresses((prevAddresses) =>
                    prevAddresses.map((address) =>
                        address.id === editingAddress.id ? editingAddress : address
                    )
                );
            } else {
                const tempId = Date.now();
                setAddresses((prevAddresses) => [
                    ...prevAddresses,
                    { ...editingAddress, id: tempId }
                ]);
            }
            setEditingAddress(null);
            setIsEditing(false);
            setIsFormChanged(true);
        } else {
            alert("Preencha todos os campos obrigatórios do endereço.");
        }
    };
    
    const handleEditAddress = (address) => {
        setEditingAddress(address);
        setIsEditing(true);
    };
    
    const handleDeleteAddress = (addressId) => {
        setAddresses((prevAddresses) => prevAddresses.filter((address) => address.id !== addressId));
        setIsFormChanged(true);
    };

    const areRequiredFieldsFilled = () => {
        const requiredFields = ['legalName', 'active', 'icmsTaxPayerIndicator'];

        if (formData.customerType === 'PESSOA_JURIDICA') {
            requiredFields.push('cnpj');
        } else if (formData.customerType === 'PESSOA_FISICA') {
            requiredFields.push('cpf');
        }

        return addresses.length > 0 && requiredFields.every((field) => {
            const value = formData[field];
            if (typeof value === 'string') {
                return value.trim() !== '';
            } else if (field === 'active') {
                return value !== undefined;
            } else {
                return !!value;
            }
        });
    };

    function removeFormatting(value) {
        if (!value) {
            return '';
        }
        return value.replace(/[^\d]/g, '');
    }

    const handleSave = async () => {
        setIsFormChanged(false);
        
        try {
            const addressesToSend = addresses.map(address => {
                const isTemporaryId = typeof address.id === 'number' && address.id > 1e12;
        
                if (isTemporaryId) {
                    const { id, ...rest } = address;
                    return rest;
                }
                return address;
            });
            
            const dataToSend = {
                ...formData,
                addresses: addressesToSend,
                cnpj: removeFormatting(formData.cnpj),
                cpf: removeFormatting(formData.cpf),
                phone: removeFormatting(formData.phone),
                cellPhone: removeFormatting(formData.cellPhone),
                simplesNacional: formData.simplesNacional === true || formData.simplesNacional === 'true',
                endConsumer: formData.endConsumer === true || formData.endConsumer === 'true',
                active: formData.active === true || formData.active === 'true',
                icmsTaxPayerIndicator: formData.icmsTaxPayerIndicator || ''
            };
    
            if (customer.id) {
                await axios.put(`/api/customers/${customer.id}`, dataToSend);
                setAlert({ type: 'success', message: 'Cliente editado com sucesso.' });
            } else {
                await axios.post('/api/customers', dataToSend);
                setAlert({ type: 'success', message: 'Cliente adicionado com sucesso.' });
            }
    
            setInProp(false);
            setTimeout(() => {
                navigate('/customers', { state: { searchTerm, page, rows, sortField, sortOrder } });
            }, 300);
        } catch (error) {
            setIsFormChanged(true);
            console.error('Erro ao salvar cliente', error);
        }
    };
   
    const handleCancel = () => {
        setInProp(false);
        setTimeout(() => {
            navigate('/customers', { state: { searchTerm, page, rows, sortField, sortOrder } });
        }, 300);
    };

    return (
        <CSSTransition in={inProp} timeout={300} classNames="fade" appear>
            <div className="form-limited-width mt-4">
                {loading ? (
                    <div className="spinner-container">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : showForm ? (
                    <div>
                        <h2 className="mb-4">{customer.id ? 'Editar Cliente' : 'Novo Cliente'}</h2>
                        <Tabs defaultActiveKey="general" className="mb-4" activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)}>
                            <Tab eventKey="general" title={<>Dados Gerais <span className="text-danger">*</span></>}>
                                <form>
                                    <div className="mb-4 p-3 border rounded">
                                        <h5 style={{marginBottom: 1 + 'em'}}>Informações Básicas</h5>
                                        <div className="mb-3">
                                            <label className="form-label">Tipo de Cliente <span className="text-danger">*</span></label>
                                            <div>
                                            <div className="form-check form-check-inline mt-1">
                                                <input
                                                    className="form-check-input"
                                                    id="pessoaJuridica"
                                                    type="radio"
                                                    name="customerType"
                                                    value="PESSOA_JURIDICA"
                                                    checked={formData.customerType === 'PESSOA_JURIDICA'}
                                                    onChange={handleInputChange}
                                                />
                                                <label className="form-check-label" htmlFor="pessoaJuridica">Pessoa Jurídica</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    id="pessoaFisica"
                                                    type="radio"
                                                    name="customerType"
                                                    value="PESSOA_FISICA"
                                                    checked={formData.customerType === 'PESSOA_FISICA'}
                                                    onChange={handleInputChange}
                                                />
                                                <label className="form-check-label" htmlFor="pessoaFisica">Pessoa Física</label>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-4">
                                                <label className="form-label">
                                                    {formData.customerType === 'PESSOA_JURIDICA' ? 'CNPJ' : 'CPF'} <span className="text-danger">*</span>
                                                </label>
                                                {formData.customerType === 'PESSOA_JURIDICA' ? (
                                                    <ReactInputMask mask="99.999.999/9999-99" value={formData.cnpj || ''} onChange={handleInputChange}>
                                                        {(inputProps) => <input {...inputProps} type="text" className="form-control" name="cnpj" required />}
                                                    </ReactInputMask>
                                                ) : (
                                                    <ReactInputMask mask="999.999.999-99" value={formData.cpf || ''} onChange={handleInputChange}>
                                                        {(inputProps) => <input {...inputProps} type="text" className="form-control" name="cpf" required />}
                                                    </ReactInputMask>
                                                )}
                                            </div>
                                            <div className="col-md-8">
                                                <label className="form-label">
                                                    {formData.customerType === 'PESSOA_JURIDICA' ? 'Razão Social' : 'Nome Completo'} <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="legalName"
                                                    value={formData.legalName || ''}
                                                    onChange={handleInputChange}
                                                    required
                                                    maxLength={150}
                                                />
                                            </div>
                                        </div>
                                        {formData.customerType === 'PESSOA_JURIDICA' ? (
                                        <div className="mb-3">
                                            <label className="form-label">Nome Fantasia</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="name"
                                                value={formData.name || ''}
                                                onChange={handleInputChange}
                                                maxLength={100}
                                            />
                                        </div>
                                        ) : ''}
                                        <div className="mb-3">
                                            <label className="form-label">Ativo <span className="text-danger">*</span></label>
                                            <select 
                                                className="form-select"
                                                name="active"
                                                value={formData.active}
                                                onChange={handleInputChange}>
                                                <option value={true}>Sim</option>
                                                <option value={false}>Não</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="mb-4 p-3 border rounded">
                                        <h5 style={{marginBottom: 1 + 'em'}}>Contato</h5>
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <label className="form-label">Telefone</label>
                                                <ReactInputMask 
                                                    mask="(99) 9999-9999" 
                                                    value={formData.phone || ''} 
                                                    onChange={handleInputChange}
                                                >
                                                    {(inputProps) => <input {...inputProps} type="text" className="form-control" name="phone" />}
                                                </ReactInputMask>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label">Celular</label>
                                                <ReactInputMask 
                                                    mask="(99) 99999-9999" 
                                                    value={formData.cellPhone || ''} 
                                                    onChange={handleInputChange}
                                                >
                                                    {(inputProps) => <input {...inputProps} type="text" className="form-control" name="cellPhone" />}
                                                </ReactInputMask>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Email</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                name="email"
                                                value={formData.email || ''}
                                                onChange={handleInputChange}
                                                maxLength={60}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Website</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="website"
                                                value={formData.website || ''}
                                                onChange={handleInputChange}
                                                maxLength={200}
                                            />
                                        </div>
                                    </div>

                                    <button
                                        type="button"
                                        className="btn btn-primary me-2"
                                        onClick={handleSave}
                                        disabled={!isFormChanged || !areRequiredFieldsFilled()}
                                    >
                                        <FontAwesomeIcon icon={faSave} /> Salvar
                                    </button>
                                    <button type="button" className="btn btn-secondary" onClick={handleCancel}>
                                        Cancelar
                                    </button>
                                </form>
                            </Tab>

                            <Tab eventKey="address" title={<>Endereços <span className="text-danger">*</span></>}>
                                {addresses.length === 0 && !isEditing ? (
                                    <div className="mb-4 p-3 border rounded">
                                        <h5>Endereços</h5>
                                        <p>Nenhum endereço adicionado.<br/>Adicione pelo menos um endereço para Salvar.</p>
                                        <button type="button" className="btn btn-primary" onClick={handleAddNewAddress}>
                                            Adicionar Endereço
                                        </button>
                                    </div>
                                ) : (
                                    <>
                                        {addresses.length > 0 && !isEditing && (
                                            <div className="mb-4 p-3 border rounded">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h5 className="mb-0">Endereços</h5>
                                                    <button 
                                                        type="button" 
                                                        className="btn btn-primary btn-sm" 
                                                        onClick={handleAddNewAddress}
                                                        style={{ marginLeft: 'auto' }}
                                                    >
                                                        <FontAwesomeIcon icon={faPlus} />
                                                    </button>
                                                </div>
                                                <ul className="list-group mb-3 mt-3">
                                                    {addresses.map((address, index) => (
                                                        <li className="list-group-item d-flex justify-content-between align-items-center"  key={address.id || `new-${index}`}>
                                                            <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                <strong>{address.description}</strong> - {address.street}, {address.number}, {address.neighborhood}
                                                            </div>
                                                            <div>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm border-secondary custom-btn me-2"
                                                                    title="Alterar endereço"
                                                                    onClick={() => handleEditAddress(address)}
                                                                >
                                                                    <FontAwesomeIcon icon={faPencil} />
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm border-secondary custom-btn me-2"
                                                                    title="Excluir endereço"
                                                                    onClick={() => handleDeleteAddress(address.id)}
                                                                >
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </button>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                        {isEditing && (
                                            <div>
                                                <div className="mb-4 p-3 border rounded">
                                                    <h5>{editingAddress.id ? 'Editar Endereço' : 'Novo Endereço'}</h5>
                                                    <div className="mb-3">
                                                        <label className="form-label">Descrição <span className="text-danger">*</span></label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="description"
                                                            value={editingAddress.description || ''}
                                                            onChange={handleAddressInputChange}
                                                            maxLength={100}
                                                            placeholder="Ex.: Endereço de Entrega"
                                                        />
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-8">
                                                            <label className="form-label">Logradouro <span className="text-danger">*</span></label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="street"
                                                                value={editingAddress.street || ''}
                                                                onChange={handleAddressInputChange}
                                                                maxLength={100}
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label className="form-label">Número <span className="text-danger">*</span></label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="number"
                                                                value={editingAddress.number || ''}
                                                                onChange={handleAddressInputChange}
                                                                maxLength={10}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <div className="col-md-8">
                                                            <label className="form-label">Bairro <span className="text-danger">*</span></label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="neighborhood"
                                                                value={editingAddress.neighborhood || ''}
                                                                onChange={handleAddressInputChange}
                                                                maxLength={60}
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <label className="form-label">CEP <span className="text-danger">*</span></label>
                                                            <ReactInputMask mask="99999-999" value={editingAddress.zipCode || ''} onChange={handleAddressInputChange}>
                                                                {(inputProps) => <input {...inputProps} type="text" className="form-control" name="zipCode" />}
                                                            </ReactInputMask>
                                                        </div>
                                                    </div>

                                                    <div className="mb-3">
                                                        <label className="form-label">Complemento</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="complement"
                                                            value={editingAddress.complement || ''}
                                                            onChange={handleAddressInputChange}
                                                            maxLength={60}
                                                        />
                                                    </div>

                                                    <div className="mb-3">
                                                        <label className="form-label">Cidade <span className="text-danger">*</span></label>
                                                        <AsyncSelect2
                                                            url="/api/cities"
                                                            value={editingAddress.city ? {
                                                                label: `${editingAddress.city.name} / ${editingAddress.city.uf}`,
                                                                value: editingAddress.city.id
                                                            } : null}
                                                            onChange={(selectedOption) => {
                                                                const selectedCity = selectedOption ? {
                                                                    id: selectedOption.value,
                                                                    name: selectedOption.label.split(' / ')[0],
                                                                    uf: selectedOption.label.split(' / ')[1]
                                                                } : null;
                                                                
                                                                setEditingAddress((prevAddress) => ({
                                                                    ...prevAddress,
                                                                    city: selectedCity
                                                                }));
                                                                setIsFormChanged(true);
                                                            }}
                                                            valueField="ibgeCode"
                                                            labelField="name"
                                                            placeholder="Selecione a cidade"
                                                            initialOptions={editingAddress.city ? [{
                                                                label: `${editingAddress.city.name} / ${editingAddress.city.uf}`,
                                                                value: editingAddress.city.id
                                                            }] : []}
                                                            labelFormatter={(item) => `${item.name} / ${item.province.uf}`}
                                                            showClear
                                                        />
                                                    </div>

                                                    <div className="mb-3">
                                                        <label className="form-label">Tipo de Endereço <span className="text-danger">*</span></label>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="shippingAddress"
                                                                checked={editingAddress.shippingAddress || false}
                                                                onChange={(e) => {
                                                                    setAddresses((prevAddresses) =>
                                                                        prevAddresses.map((address) =>
                                                                            address.id === editingAddress.id
                                                                                ? { ...address, shippingAddress: e.target.checked }
                                                                                : { ...address, shippingAddress: false }
                                                                        )
                                                                    );
                                                                    setEditingAddress((prevAddress) => ({
                                                                        ...prevAddress,
                                                                        shippingAddress: e.target.checked
                                                                    }));
                                                                    setIsFormChanged(true);
                                                                }}
                                                            />
                                                            <label className="form-check-label">
                                                                Endereço de Entrega
                                                            </label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="billingAddress"
                                                                checked={editingAddress.billingAddress || false}
                                                                onChange={(e) => {
                                                                    setAddresses((prevAddresses) =>
                                                                        prevAddresses.map((address) =>
                                                                            address.id === editingAddress.id
                                                                                ? { ...address, billingAddress: e.target.checked }
                                                                                : { ...address, billingAddress: false }
                                                                        )
                                                                    );
                                                                    setEditingAddress((prevAddress) => ({
                                                                        ...prevAddress,
                                                                        billingAddress: e.target.checked
                                                                    }));
                                                                    setIsFormChanged(true);
                                                                }}
                                                            />
                                                            <label className="form-check-label">
                                                                Endereço de Cobrança
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className='mt-4'>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary me-2"
                                                            onClick={handleSaveAddress}
                                                            disabled={!isAddressValid()}
                                                        >
                                                            {editingAddress.id ? 'Atualizar Endereço' : 'Adicionar à Lista'}
                                                        </button>

                                                        <button
                                                            type="button"
                                                            className="btn btn-secondary"
                                                            onClick={() => {
                                                                setIsEditing(false);
                                                                setEditingAddress(null);
                                                            }}
                                                        >
                                                            Cancelar
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {!isEditing && (
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary me-2"
                                                    onClick={handleSave}
                                                    disabled={!isFormChanged || !areRequiredFieldsFilled()}
                                                >
                                                    <FontAwesomeIcon icon={faSave} /> Salvar
                                                </button>
                                                <button type="button" className="btn btn-secondary" onClick={handleCancel}>
                                                    Cancelar
                                                </button>
                                            </div>
                                        )}
                                    </>
                                )}
                            </Tab>

                            <Tab eventKey="tax" title={<>Dados Fiscais <span className="text-danger">*</span></>}>
                                <form>
                                    <div className="mb-4 p-3 border rounded">
                                        <h5 style={{marginBottom: 1 + 'em'}}>Informações fiscais</h5>
                                        <div className="mb-3">
                                            <label className="form-label">
                                                Indicador de Contribuinte de ICMS
                                                <InfoPopover
                                                    content={
                                                        <div style={{ textAlign: 'justify' }}>
                                                            <p>Este campo define se a empresa é contribuinte do ICMS (Imposto sobre Circulação de Mercadorias e Serviços):</p>
                                                            <ul>
                                                                <li><strong>1 - Contribuinte ICMS:</strong> A empresa possui Inscrição Estadual e recolhe ICMS.</li>
                                                                <li><strong>2 - Isento de ICMS:</strong> A empresa está isenta de inscrição estadual para o ICMS.</li>
                                                                <li><strong>9 - Não Contribuinte:</strong> A empresa não é contribuinte do ICMS, como prestadores de serviços ou consumidores finais.</li>
                                                            </ul>
                                                            A escolha correta deste indicador impacta a emissão de notas fiscais e a tributação.
                                                        </div>
                                                    }
                                                />{'\u00A0'}<span className="text-danger">*</span>
                                            </label>
                                            <select
                                                className="form-select"
                                                name="icmsTaxPayerIndicator"
                                                value={formData.icmsTaxPayerIndicator || ''}
                                                onChange={handleInputChange}
                                            >
                                                <option value="">Selecione</option>
                                                <option value="1">1 - Contribuinte ICMS</option>
                                                <option value="2">2 - Isento de inscrição no cadastro de contribuintes do ICMS</option>
                                                <option value="9">9 - Não contribuinte</option>
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Simples Nacional <span className="text-danger">*</span></label>
                                            <select 
                                                className="form-select"
                                                name="simplesNacional"
                                                value={formData.simplesNacional}
                                                onChange={handleInputChange}
                                            >
                                                <option value="true">Sim</option>
                                                <option value="false">Não</option>
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Consumidor Final <span className="text-danger">*</span></label>
                                            <select 
                                                className="form-select"
                                                name="endConsumer"
                                                value={formData.endConsumer}
                                                onChange={handleInputChange}
                                            >
                                                <option value="true">Sim</option>
                                                <option value="false">Não</option>
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Inscrição estadual</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name="stateRegistration"
                                                value={formData.stateRegistration || ''}
                                                onChange={handleInputChange}
                                                max="99999999999999"
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Inscrição municipal</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="cityRegistration"
                                                value={formData.cityRegistration || ''}
                                                onChange={handleInputChange}
                                                maxLength={15}
                                            />
                                        </div>
                                    </div>

                                    <button
                                        type="button"
                                        className="btn btn-primary me-2"
                                        onClick={handleSave}
                                        disabled={!isFormChanged || !areRequiredFieldsFilled()}
                                    >
                                        <FontAwesomeIcon icon={faSave} /> Salvar
                                    </button>
                                    <button type="button" className="btn btn-secondary" onClick={handleCancel}>
                                        Cancelar
                                    </button>
                                </form>
                            </Tab>
                        </Tabs>
                    </div>
                ) : null}

                <ConfirmationCnpj
                    show={showConfirmCnpj}
                    onHide={() => setShowConfirmCnpj(false)}
                    onConfirm={() => handleSelectCnpj(cnpjToConfirm)}
                    cnpjData={cnpjToConfirm}
                />
            </div>
        </CSSTransition>
    );
}

export default CustomerEdit;
