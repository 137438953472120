import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import axios from './AxiosInstance';
import AsyncSelect2 from './AsyncSelect2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { Tabs, Tab } from 'react-bootstrap';
import '../assets/styles/App.css';
import ReactInputMask from 'react-input-mask';
import ConfirmationCnpj from './ConfirmationCnpj';
import { CSSTransition } from 'react-transition-group';

function SupplierEdit() {
    const { setAlert, axiosConfigured } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        supplierType: 'PESSOA_JURIDICA',
        name: '',
        legalName: '',
        cnpj: '',
        stateRegistration: '',
        cityRegistration: '',
        phone: '',
        cellPhone: '',
        email: '',
        website: '',
        active: true,
        street: '',
        number: '',
        complement: '',
        neighborhood: '',
        zipCode: '',
        city: null,
        simplesNacional: false
    });
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [selectedCity, setSelectedCity] = useState(null);
    const [showConfirmCnpj, setShowConfirmCnpj] = useState(false);
    const [cnpjToConfirm, setCnpjToConfirm] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const { supplier: initialSupplier, searchTerm, page, rows, sortField, sortOrder } = location.state || {};
    const [isFormChanged, setIsFormChanged] = useState(false);
    const hasFetchedData = useRef(false);
    const [supplier, setSupplier] = useState(initialSupplier || {});
    const [inProp, setInProp] = useState(false);
    const loadingTimeout = useRef(null);
    const lastValidatedCnpj = useRef('');

    const loadSupplier = useCallback(async () => {
        try {
            const response = await axios.get(`/api/suppliers/${id}`);
            clearTimeout(loadingTimeout.current);
            initializeForm(response.data);
            setShowForm(true);
        } catch (error) {
            console.error('Erro ao carregar fornecedor:', error);
            if (error.response && error.response.status === 404) {
                navigate('/suppliers', { state: { searchTerm, page, rows, sortField, sortOrder } });
            }
        } finally {
            setLoading(false);
        }
    }, [id, navigate, page, rows, searchTerm, sortField, sortOrder]);

    useEffect(() => {
        if (axiosConfigured && !hasFetchedData.current && id !== 'new') {
            loadingTimeout.current = setTimeout(() => {
                setLoading(true);
            }, 500);
            loadSupplier();
            hasFetchedData.current = true;
        } else if (id === 'new') {
            clearTimeout(loadingTimeout.current);
            setLoading(false);
            setFormData((prevFormData) => ({
                ...prevFormData,
                supplierType: 'PESSOA_JURIDICA'
            }));
            setShowForm(true); // Mostrar o formulário para novos itens
        }
    }, [axiosConfigured, id, loadSupplier]);

    useEffect(() => {
        setInProp(true);
    }, []);

    const initializeForm = (supplier) => {
        setSupplier(supplier);
        setFormData({
            supplierType: supplier.supplierType || 'PESSOA_JURIDICA',
            name: supplier.name || '',
            legalName: supplier.legalName || '',
            cnpj: supplier.cnpj || '',
            cpf: supplier.cpf || '',
            stateRegistration: supplier.stateRegistration || '',
            cityRegistration: supplier.cityRegistration || '',
            phone: supplier.phone || '',
            cellPhone: supplier.cellPhone || '',
            email: supplier.email || '',
            website: supplier.website || '',
            active: supplier.active || 'true',
            street: supplier.street || '',
            number: supplier.number || '',
            complement: supplier.complement || '',
            neighborhood: supplier.neighborhood || '',
            zipCode: supplier.zipCode || '',
            city: supplier.city || null,
            simplesNacional: supplier.isSimples? supplier.isSimples: supplier.simplesNacional || 'false'
        });
        setSelectedCity(supplier.city ? {
            label: `${supplier.city.name} / ${supplier.city.uf}`,
            value: supplier.city.id || '',
            name: supplier.city.name || '',
            province: supplier.city.uf || ''
        } : null);
    };

    const handleCnpj = useCallback(async (cnpj) => {
        try {
            const response = await axios.get(`/api/cnpj/${cnpj}`);
            if (response.data) {
                //setAlert({ type: 'info', message: 'Dados do CNPJ sugeridos no formulário.' });
                confirmCnpj(response.data);
                setIsFormChanged(true);
            } else {
                response.data.cnpj = cnpj;
            }
            if (id !== "new") {
                response.data.id = id;
            }
            //initializeForm(response.data);
        } catch (error) {
            //console.info("Não foi possível consultar o CNPJ");
        }
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let cnpj = '';
        let parsedValue = value;

        if (name === 'stateRegistration' && value.length > 14) {
            return;
        }

        if (name === 'simplesNacional' || name === 'endConsumer' || name === 'active') {
            parsedValue = value === 'true';
        }
    
        setFormData((prevData) => {
            const isChanged = prevData[name] !== parsedValue;

            if (name === 'supplierType' && isChanged) {
                setIsFormChanged(true);
                return {
                    ...prevData,
                    supplierType: parsedValue,
                    cnpj: '',
                    cpf: '',
                    legalName: '',
                    name: ''
                };
            }
    
            if (name === "cnpj" && isChanged) {
                cnpj = removeFormatting(value);
                if (cnpj.length === 14 && cnpj !== lastValidatedCnpj.current) {
                    lastValidatedCnpj.current = cnpj;
                    handleCnpj(cnpj);
                }
            }
    
            if (isChanged) {
                setIsFormChanged(true);
            }

            return { ...prevData, [name]: parsedValue };
        });
    };

    const confirmCnpj = (cnpjToConfirm) => {
        setCnpjToConfirm(cnpjToConfirm);
        setShowConfirmCnpj(true);
    };

    const handleSelectCnpj = async (supplier) => {
        supplier.phone = supplier.phone1;
        initializeForm(supplier);
        setShowConfirmCnpj(false);
        setIsFormChanged(true);

    };

    const handleCityChange = (selectedOption) => {
        const selectedCity = selectedOption ? {
            id: selectedOption.value,
            name: selectedOption.name,
            uf: selectedOption.province
        } : null;
        
        setSelectedCity(selectedOption);
        setFormData((prevData) => {
            const isChanged = prevData.city !== selectedCity;
            if (isChanged) {
                setIsFormChanged(true);
            }
            return { ...prevData, city: selectedCity };
        });
    };

    const areRequiredFieldsFilled = () => {
        const requiredFields = ['legalName', 'active', 'city'];

        if (formData.supplierType === 'PESSOA_JURIDICA') {
            requiredFields.push('cnpj');
        } else if (formData.supplierType === 'PESSOA_FISICA') {
            requiredFields.push('cpf');
        }
        return requiredFields.every((field) => {
            const value = formData[field];
            if (typeof value === 'string') {
                return value.trim() !== '';
            } else if (field === 'active') {
                return value !== undefined;
            } else {
                return !!value;
            }
        });
    };

    function removeFormatting(value) {
        if (!value) {
            return '';
        }
        return value.replace(/[^\d]/g, '');
    }

    const handleSave = async () => {
        setIsFormChanged(false);

        try {
            const dataToSend = {
                ...formData,
                city: selectedCity ? {
                    id: selectedCity.value,
                    name: selectedCity.name,
                    uf: selectedCity.province
                } : null,
                simplesNacional: formData.simplesNacional === true || formData.simplesNacional === 'true'
            };

            dataToSend.cnpj = removeFormatting(dataToSend.cnpj);
            dataToSend.cpf = removeFormatting(dataToSend.cpf);
            dataToSend.zipCode = removeFormatting(dataToSend.zipCode);
            dataToSend.phone = removeFormatting(dataToSend.phone);
            dataToSend.cellPhone = removeFormatting(dataToSend.cellPhone);
    
            if (supplier.id) {
                await axios.put(`/api/suppliers/${supplier.id}`, dataToSend);
                setAlert({ type: 'success', message: 'Fornecedor editado com sucesso.' });
            } else {
                await axios.post('/api/suppliers', dataToSend);
                setAlert({ type: 'success', message: 'Fornecedor adicionado com sucesso.' });
            }
            setInProp(false);
            setTimeout(() => {
                navigate('/suppliers', { state: { searchTerm, page, rows, sortField, sortOrder } });
            }, 300);
        } catch (error) {
            setIsFormChanged(true);
            //console.error('Erro ao salvar fornecedor', error);
        }
    };
    
    const handleCancel = () => {
        setInProp(false);
        setTimeout(() => {
            navigate('/suppliers', { state: { searchTerm, page, rows, sortField, sortOrder } });
        }, 300);
    };

    return (
        <CSSTransition in={inProp} timeout={300} classNames="fade" appear>
            <div className="form-limited-width mt-4">
                {loading ? (
                    <div className="spinner-container">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : showForm ? (
                    <div>
                        <h2 className="mb-4">{supplier.id ? 'Editar Fornecedor' : 'Novo Fornecedor'}</h2>
                        <Tabs defaultActiveKey="general" className="mb-4">
                            <Tab eventKey="general" title={<>Dados Gerais <span className="text-danger">*</span></>}>
                                <form>
                                    <div className="mb-4 p-3 border rounded">
                                        <h5 style={{marginBottom: 1 + 'em'}}>Informações Básicas</h5>
                                        <div className="mb-3">
                                            <label className="form-label">Tipo de Cliente <span className="text-danger">*</span></label>
                                            <div>
                                            <div className="form-check form-check-inline mt-1">
                                                <input
                                                    className="form-check-input"
                                                    id="pessoaJuridica"
                                                    type="radio"
                                                    name="supplierType"
                                                    value="PESSOA_JURIDICA"
                                                    checked={formData.supplierType === 'PESSOA_JURIDICA'}
                                                    onChange={handleInputChange}
                                                />
                                                <label className="form-check-label" htmlFor="pessoaJuridica">Pessoa Jurídica</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    id="pessoaFisica"
                                                    type="radio"
                                                    name="supplierType"
                                                    value="PESSOA_FISICA"
                                                    checked={formData.supplierType === 'PESSOA_FISICA'}
                                                    onChange={handleInputChange}
                                                />
                                                <label className="form-check-label" htmlFor="pessoaFisica">Pessoa Física</label>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-4">
                                                <label className="form-label">
                                                    {formData.supplierType === 'PESSOA_JURIDICA' ? 'CNPJ' : 'CPF'} <span className="text-danger">*</span>
                                                </label>
                                                {formData.supplierType === 'PESSOA_JURIDICA' ? (
                                                    <ReactInputMask mask="99.999.999/9999-99" value={formData.cnpj || ''} onChange={handleInputChange}>
                                                        {(inputProps) => <input {...inputProps} type="text" className="form-control" name="cnpj" required />}
                                                    </ReactInputMask>
                                                ) : (
                                                    <ReactInputMask mask="999.999.999-99" value={formData.cpf || ''} onChange={handleInputChange}>
                                                        {(inputProps) => <input {...inputProps} type="text" className="form-control" name="cpf" required />}
                                                    </ReactInputMask>
                                                )}
                                            </div>
                                            <div className="col-md-8">
                                                <label className="form-label">
                                                    {formData.supplierType === 'PESSOA_JURIDICA' ? 'Razão Social' : 'Nome Completo'} <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="legalName"
                                                    value={formData.legalName || ''}
                                                    onChange={handleInputChange}
                                                    required
                                                    maxLength={150}
                                                />
                                            </div>
                                        </div>
                                        {formData.supplierType === 'PESSOA_JURIDICA' ? (
                                        <div className="mb-3">
                                            <label className="form-label">Nome Fantasia</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="name"
                                                value={formData.name || ''}
                                                onChange={handleInputChange}
                                                maxLength={100}
                                            />
                                        </div>
                                        ) : ''}
                                        <div className="mb-3">
                                            <label className="form-label">Ativo <span className="text-danger">*</span></label>
                                            <select 
                                                className="form-select"
                                                name="active"
                                                value={formData.active}
                                                onChange={handleInputChange}>
                                                <option value={true}>Sim</option>
                                                <option value={false}>Não</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="mb-4 p-3 border rounded">
                                        <h5 style={{marginBottom: 1 + 'em'}}>Contato</h5>
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <label className="form-label">Telefone</label>
                                                <ReactInputMask 
                                                    mask="(99) 9999-9999" 
                                                    value={formData.phone || ''} 
                                                    onChange={handleInputChange}
                                                >
                                                    {(inputProps) => <input {...inputProps} type="text" className="form-control" name="phone" />}
                                                </ReactInputMask>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label">Celular</label>
                                                <ReactInputMask 
                                                    mask="(99) 99999-9999" 
                                                    value={formData.cellPhone || ''} 
                                                    onChange={handleInputChange}
                                                >
                                                    {(inputProps) => <input {...inputProps} type="text" className="form-control" name="cellPhone" />}
                                                </ReactInputMask>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Email</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                name="email"
                                                value={formData.email || ''}
                                                onChange={handleInputChange}
                                                maxLength={60}
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Website</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="website"
                                                value={formData.website || ''}
                                                onChange={handleInputChange}
                                                maxLength={200}
                                            />
                                        </div>
                                    </div>

                                    <button
                                        type="button"
                                        className="btn btn-primary me-2"
                                        onClick={handleSave}
                                        disabled={!isFormChanged || !areRequiredFieldsFilled()}
                                    >
                                        <FontAwesomeIcon icon={faSave} /> Salvar
                                    </button>
                                    <button type="button" className="btn btn-secondary" onClick={handleCancel}>
                                        Cancelar
                                    </button>
                                </form>
                            </Tab>

                            <Tab eventKey="address" title={<>Endereço <span className="text-danger">*</span></>}>
                                <form>
                                    <div className="mb-4 p-3 border rounded">
                                        <h5 style={{marginBottom: 1 + 'em'}}>Endereço</h5>
                                        <div className="row mb-3">
                                            <div className="col-md-8">
                                                <label className="form-label">Logradouro</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="street"
                                                    value={formData.street || ''}
                                                    onChange={handleInputChange}
                                                    maxLength={100}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label">Número</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="number"
                                                    value={formData.number || ''}
                                                    onChange={handleInputChange}
                                                    maxLength={10}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-8">
                                                <label className="form-label">Bairro</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="neighborhood"
                                                    value={formData.neighborhood || ''}
                                                    onChange={handleInputChange}
                                                    maxLength={60}
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label">CEP</label>
                                                <ReactInputMask mask="99999-999" value={formData.zipCode || ''} onChange={handleInputChange}>
                                                    {(inputProps) => <input {...inputProps} type="text" className="form-control" name="zipCode" />}
                                                </ReactInputMask>
                                            </div>
                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-8">
                                                <label className="form-label">Cidade <span className="text-danger">*</span></label>
                                                <AsyncSelect2
                                                    url="/api/cities"
                                                    value={selectedCity}
                                                    onChange={handleCityChange}
                                                    valueField="ibgeCode"
                                                    labelField="name"
                                                    placeholder="Selecione a cidade"
                                                    initialOptions={selectedCity ? [selectedCity] : []}
                                                    labelFormatter={(item) => `${item.name} / ${item.province.uf}`}
                                                    showClear
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label">Complemento</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="complement"
                                                    value={formData.complement}
                                                    onChange={handleInputChange}
                                                    maxLength={60}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <button
                                        type="button"
                                        className="btn btn-primary me-2"
                                        onClick={handleSave}
                                        disabled={!isFormChanged || !areRequiredFieldsFilled()}
                                    >
                                        <FontAwesomeIcon icon={faSave} /> Salvar
                                    </button>
                                    <button type="button" className="btn btn-secondary" onClick={handleCancel}>
                                        Cancelar
                                    </button>
                                </form>
                            </Tab>

                            <Tab eventKey="tax" title={<>Dados Fiscais <span className="text-danger">*</span></>}>
                                <form>
                                    <div className="mb-4 p-3 border rounded">
                                        <h5 style={{marginBottom: 1 + 'em'}}>Informações fiscais</h5>
                                        <div className="mb-3">
                                            <label className="form-label">Simples Nacional <span className="text-danger">*</span></label>
                                            <select 
                                                className="form-select"
                                                name="simplesNacional"
                                                value={formData.simplesNacional}
                                                onChange={handleInputChange}
                                            >
                                                <option value="true">Sim</option>
                                                <option value="false">Não</option>
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Inscrição estadual</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name="stateRegistration"
                                                value={formData.stateRegistration || ''}
                                                onChange={handleInputChange}
                                                max="99999999999999"
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Inscrição municipal</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="cityRegistration"
                                                value={formData.cityRegistration || ''}
                                                onChange={handleInputChange}
                                                maxLength={15}
                                            />
                                        </div>
                                    </div>

                                    <button
                                        type="button"
                                        className="btn btn-primary me-2"
                                        onClick={handleSave}
                                        disabled={!isFormChanged || !areRequiredFieldsFilled()}
                                    >
                                        <FontAwesomeIcon icon={faSave} /> Salvar
                                    </button>
                                    <button type="button" className="btn btn-secondary" onClick={handleCancel}>
                                        Cancelar
                                    </button>
                                </form>
                            </Tab>
                        </Tabs>
                    </div>
                ) : null}

                <ConfirmationCnpj
                    show={showConfirmCnpj}
                    onHide={() => setShowConfirmCnpj(false)}
                    onConfirm={() => handleSelectCnpj(cnpjToConfirm)}
                    cnpjData={cnpjToConfirm}
                />
            </div>
        </CSSTransition>
    );
}

export default SupplierEdit;
