import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { AuthContext } from './AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSort, faSortUp, faSortDown, faLock, faLockOpen, faPencil } from '@fortawesome/free-solid-svg-icons';
import axios from './AxiosInstance';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/styles/App.css';
import { Paginator } from 'primereact/paginator';
import StaticSelect from './StaticSelect'; // Importa o componente StaticSelect
import { Modal, Button } from 'react-bootstrap';

function ConfirmationModal({ show, onHide, onConfirm, title, message }) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Cancelar</Button>
        <Button variant="danger" onClick={onConfirm}>Confirmar</Button>
      </Modal.Footer>
    </Modal>
  );
}

function EditModal({ show, onHide, onSave, formData, setFormData, handleInputChange, isFormValid, rolesOptions, rolesOptionsDfe, formType }) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{formType === 'add' ? 'Adicionar Usuário' : 'Editar Usuário'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="mb-3">
            <label className="form-label">Empresa</label>
            <input type="text" className="form-control" name="companyName" value={formData.companyName} disabled />
          </div>
          <div className="mb-3">
            <label className="form-label">Nome <span className="text-danger">*</span></label>
            <input type="text" className="form-control" name="userName" value={formData.userName} onChange={handleInputChange} required maxLength={100} />
          </div>
          <div className="mb-3">
            <label className="form-label">Email <span className="text-danger">*</span></label>
            <input type="email" className="form-control" name="userEmail" value={formData.userEmail} onChange={handleInputChange} required maxLength={100} />
          </div>
          <div className="mb-3">
            <label className="form-label d-block">Papel para Estoque<span className="text-danger">*</span></label>
            <StaticSelect
              options={rolesOptions}
              value={rolesOptions.find(option => option.value === formData.role)}
              onChange={(option) => setFormData({ ...formData, role: option.value })}
              placeholder="Selecione um papel (Estoque)"
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label d-block">Papel para documentos fiscais<span className="text-danger">*</span></label>
            <StaticSelect
              options={rolesOptionsDfe}
              value={rolesOptionsDfe.find(option => option.value === formData.roleDfe)}
              onChange={(option) => setFormData({ ...formData, roleDfe: option.value })}
              placeholder="Selecione um papel (Documentos fiscais)"
              required
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Cancelar</Button>
        <Button variant="primary" onClick={onSave} disabled={!isFormValid()}>Salvar</Button>
      </Modal.Footer>
    </Modal>
  );
}

function User() {
  const { auth, ROLE_ESTOQUE_HIERARCHY, ROLE_DFE_HIERARCHY, setAlert, axiosConfigured } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [formVisible, setFormVisible] = useState(false);
  const [formType, setFormType] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [formData, setFormData] = useState({
    companyId: auth.companyId,
    companyName: auth.companyName,
    userName: '',
    userEmail: '',
    role: '',
    roleDfe: '',
    userCellPhone: ''
  });
  const [emailError, setEmailError] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(10);
  const [sortField, setSortField] = useState('name');
  const [sortOrder, setSortOrder] = useState(1); // 1 para ascendente, -1 para descendente
  const [loading, setLoading] = useState(false); // Estado de carregamento
  const hasFetched = useRef(false); // Ref para controlar se a função fetchUsers já foi chamada
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [userToConfirm, setUserToConfirm] = useState(null);
  const [confirmationAction, setConfirmationAction] = useState('');

  const fetchUsers = useCallback(async (page, rows, sortField, sortOrder) => {
    setLoading(true); // Inicia o carregamento
    try {
      const response = await axios.get('/api/users', {
        params: {
          search: searchTerm,
          page: page,
          size: rows,
          sort: `${sortField},${sortOrder === 1 ? 'asc' : 'desc'}`
        }
      });
      const userData = response.data.content || [];
      setUsers(userData);
      setTotalRecords(response.data.totalElements || 0);
    } catch (error) {
      console.error('Erro ao buscar usuários', error);
    } finally {
      setLoading(false); // Termina o carregamento
    }
  }, [searchTerm]);

  useEffect(() => {
    if (axiosConfigured && !hasFetched.current) {
      fetchUsers(page, rows, sortField, sortOrder);
      hasFetched.current = true; // Marca como chamada
    }
  }, [axiosConfigured, page, rows, sortField, sortOrder, fetchUsers]);

  const handleSearch = async () => {
    setPage(0); // Reinicia a página para 0 ao buscar
    fetchUsers(0, rows, sortField, sortOrder);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddEmployee = () => {
    setFormType('add');
    setFormVisible(true);
    setFormData({
      companyId: auth.companyId,
      companyName: auth.companyName,
      userName: '',
      userEmail: '',
      role: '',
      roleDfe: '',
      userCellPhone: ''
    });
    setEmailError('');
  };

  const handleEditUser = (user) => {
    setFormType('edit');
    setFormVisible(true);
    setSelectedUser(user);
    setFormData({
      companyId: auth.companyId,
      companyName: auth.companyName,
      userName: user.userName,
      userEmail: user.userEmail,
      role: user.role,
      roleDfe: user.roleDfe,
      userCellPhone: user.userCellPhone
    });
    setEmailError('');
  };

  const handleSave = async () => {
    if (!isFormValid()) {
      return;
    }

    const dataToSend = {
      ...formData,
      companyId: formData.companyId,
    };
    if (formType === 'add') {
      try {
        await axios.post('/api/users', dataToSend);
        fetchUsers(page, rows, sortField, sortOrder);
        setFormVisible(false);
        setAlert({ type: 'success', message: 'Usuário adicionado com sucesso.' });
      } catch (error) {
        console.error('Erro ao adicionar usuário', error);
      }
    } else if (formType === 'edit') {
      try {
        await axios.put(`/api/users/${selectedUser.id}`, dataToSend);
        fetchUsers(page, rows, sortField, sortOrder);
        setFormVisible(false);
        setAlert({ type: 'success', message: 'Usuário editado com sucesso.' });
      } catch (error) {
        console.error('Erro ao editar usuário', error);
      }
    }
  };

  const handleDeleteUser = (user) => {
    setUserToConfirm(user);
    setConfirmationAction('delete');
    setShowConfirmation(true);
  };

  const handleActivateUser = (user) => {
    setUserToConfirm(user);
    setConfirmationAction('activate');
    setShowConfirmation(true);
  };

  const confirmAction = async () => {
    try {
      if (confirmationAction === 'delete') {
        await axios.delete(`/api/users/${userToConfirm.id}`);
        setAlert({ type: 'success', message: 'Usuário desativado com sucesso.' });
      } else if (confirmationAction === 'activate') {
        await axios.put(`/api/users/${userToConfirm.id}/activate`);
        setAlert({ type: 'success', message: 'Usuário ativado com sucesso.' });
      }
      fetchUsers(page, rows, sortField, sortOrder);
      setShowConfirmation(false);
    } catch (error) {
      console.error(`Erro ao ${confirmationAction === 'delete' ? 'desativar' : 'ativar'} usuário`, error);
    }
  };

  const roleLabels = {
    ROLE_ESTOQUE_ADMIN: 'Administrador',
    ROLE_ESTOQUE_VIEWER: 'Somente visualização',
    ROLE_ESTOQUE_USER: 'Usuário',
    ROLE_ESTOQUE_STOCK_KEEPER: 'Operador de Estoque',
    ROLE_ESTOQUE_MANAGER: 'Gerente'
  };

  const roleLabelsDfe = {
    ROLE_DFE_ADMIN: 'Administrador',
    ROLE_DFE_VIEWER: 'Somente visualização',
    ROLE_DFE_USER: 'Usuário',
    ROLE_DFE_MANAGER: 'Gerente'
  };

  const rolesOptions = ROLE_ESTOQUE_HIERARCHY.slice().reverse().map(role => ({
    label: roleLabels[role],
    value: role
  }));

  const rolesOptionsDfe = ROLE_DFE_HIERARCHY.slice().reverse().map(roleDfe => ({
    label: roleLabelsDfe[roleDfe],
    value: roleDfe
  }));

  const isFormValid = () => {
    return formData.userName && formData.userEmail && formData.role && formData.roleDfe && !emailError;
  };

  const onPageChange = (event) => {
    setPage(event.page);
    setRows(event.rows);
    fetchUsers(event.page, event.rows, sortField, sortOrder);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSort = (field) => {
    const order = sortField === field && sortOrder === 1 ? -1 : 1;
    setSortField(field);
    setSortOrder(order);
    fetchUsers(page, rows, field, order);
  };

  const getSortIcon = (field) => {
    if (sortField === field) {
      return sortOrder === 1 ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
    }
    return <FontAwesomeIcon icon={faSort} />;
  };

  return (
    <div>
      <h2 className="mb-4">Usuários</h2>

      <div className="d-flex justify-content-between mb-4">
        <button className="btn btn-primary" onClick={handleAddEmployee}>
          <span className="d-none d-md-inline">Novo Usuário</span>
          <span className="d-inline d-md-none">Novo</span>
        </button>
        <div className="input-group" style={{ maxWidth: '300px' }}>
          <input
            type="text"
            className="form-control"
            placeholder="Pesquisar"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <button className="btn btn-outline-secondary btn-search" onClick={handleSearch}>
            <i><FontAwesomeIcon icon={faSearch} /></i>
          </button>
        </div>
      </div>

      {loading ? (
        <div className="spinner-container">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : users.length > 0 ? (
        <>
          <table className="table table-striped">
            <thead>
              <tr>
                <th className="sortable-column" onClick={() => handleSort('name')}>Nome {getSortIcon('name')}</th>
                <th className="sortable-column d-none d-lg-table-cell" onClick={() => handleSort('role')}>Papel Estoque {getSortIcon('role')}</th>
                <th className="sortable-column d-none d-lg-table-cell" onClick={() => handleSort('roleDfe')}>Papel DFE {getSortIcon('roleDfe')}</th>
                <th className="sortable-column d-none d-md-table-cell" onClick={() => handleSort('active')}>Ativo {getSortIcon('active')}</th>
                <th className='text-end'>Ações</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td className="ellipsis">{user.userName}</td>
                  <td className="d-none d-lg-table-cell">{roleLabels[user.role]}</td>
                  <td className="d-none d-lg-table-cell">{roleLabelsDfe[user.roleDfe]}</td>
                  <td className="d-none d-md-table-cell">{user.active ? 'Sim' : 'Não'}</td>
                  <td className='text-end'>
                    <button className="btn btn-sm border-secondary custom-btn me-2" title="Editar" onClick={() => handleEditUser(user)}>
                        <FontAwesomeIcon icon={faPencil} />
                    </button>
                    {user.active ? (
                      <button className="btn btn-sm border-secondary custom-btn me-2" title="Desativar" onClick={() => handleDeleteUser(user)}>
                        <FontAwesomeIcon icon={faLockOpen} className="text-success" />
                      </button>
                    ) : (
                      <button className="btn btn-sm border-secondary custom-btn me-2" title="Ativar" onClick={() => handleActivateUser(user)}>
                        <FontAwesomeIcon icon={faLock} className="text-danger" />
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <Paginator
            first={page * rows}
            rows={rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50]}
            onPageChange={onPageChange}
          />
        </>
      ) : (
        <div className="no-data">
          <FontAwesomeIcon icon={faSearch} size="6x" />
          <p>Ainda não há dados por aqui, ou sua pesquisa não retornou resultados.</p>
        </div>
      )}

      <EditModal
        show={formVisible}
        onHide={() => setFormVisible(false)}
        onSave={handleSave}
        formData={formData}
        setFormData={setFormData}
        handleInputChange={handleInputChange}
        isFormValid={isFormValid}
        rolesOptions={rolesOptions}
        rolesOptionsDfe={rolesOptionsDfe}
        formType={formType}
      />

      <ConfirmationModal
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        onConfirm={confirmAction}
        title={`Confirmar ${confirmationAction === 'delete' ? 'Desativação' : 'Ativação'}`}
        message={`Deseja realmente ${confirmationAction === 'delete' ? 'desativar' : 'ativar'} o usuário [${userToConfirm?.userName}]?`}
      />
    </div>
  );
}

export default User;
