import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from './AxiosInstance';
import { jwtDecode } from 'jwt-decode';

export const AuthContext = createContext();

// Definição das hierarquias de papéis (roles)
const ROLE_ESTOQUE_HIERARCHY = ['ROLE_ESTOQUE_VIEWER', 'ROLE_ESTOQUE_STOCK_KEEPER', 'ROLE_ESTOQUE_USER', 'ROLE_ESTOQUE_MANAGER', 'ROLE_ESTOQUE_ADMIN'];
const ROLE_DFE_HIERARCHY = ['ROLE_DFE_VIEWER', 'ROLE_DFE_USER', 'ROLE_DFE_MANAGER', 'ROLE_DFE_ADMIN'];

// Funções de verificação de papéis para Estoque e DFE
const hasRequiredRoleEstoque = (userRoles, requiredRole) => {
  const requiredRoleIndex = ROLE_ESTOQUE_HIERARCHY.indexOf(requiredRole);
  return userRoles.some(role => ROLE_ESTOQUE_HIERARCHY.indexOf(role) >= requiredRoleIndex);
};

const hasRequiredRoleDfe = (userRoles, requiredRole) => {
  const requiredRoleIndex = ROLE_DFE_HIERARCHY.indexOf(requiredRole);
  return userRoles.some(role => ROLE_DFE_HIERARCHY.indexOf(role) >= requiredRoleIndex);
};

// Provider do AuthContext
export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    token: localStorage.getItem('token'),
    roles: [],
    expired: false,
    intentionalLogout: false,
  });
  const [axiosConfigured, setAxiosConfigured] = useState(false);
  const [alert, setAlert] = useState({ type: '', message: '' });
  const navigate = useNavigate();

  // Função de logout que limpa o token e redireciona para o login
  const logout = (intentional = false) => {
    localStorage.removeItem('token');
    if(!intentional) {
      localStorage.setItem('sessionExpired', 'true');
    }
    setAuth({ token: null, roles: [], expired: !intentional, intentionalLogout: intentional });
    navigate('/login');
  };

  // Função para decodificar o token JWT e atualizar o estado da autenticação
  const decodeToken = (token) => {
    try {
      const decoded = jwtDecode(token);
      return {
        token,
        roles: decoded.roles || [],
        loginUser: decoded.loginUser || '',
        cellPhone: decoded.cellPhone || '',
        userName: decoded.userName || '',
        companyId: decoded.companyId || '',
        companyName: decoded.companyName || '',
        expired: false,
        intentionalLogout: false,
      };
    } catch (error) {
      return { token: null, roles: [], loginUser: '', cellPhone: '', userName: '', companyId: '', companyName: '', expired: true, intentionalLogout: false };
    }
  };

  // Configuração dos interceptadores do Axios
  useEffect(() => {
    const setupAxiosInterceptors = () => {
      axios.interceptors.request.use(
        (config) => {
          const token = localStorage.getItem('token');
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );

      axios.interceptors.response.use(
        (response) => {
          const newToken = response.headers['authorization'];
          if (newToken) {
            const token = newToken.replace('Bearer ', '');
            localStorage.setItem('token', token);
            setAuth(decodeToken(token));
          }
      
          return response;
        },
        (error) => {
          if (error.response) {
            if (error.response.status === 403) {
              navigate('/home');
            } else if ([400, 404, 500].includes(error.response.status)) {
              const errorMessage = error.response.headers['x-error-message'];
              const warnMessage = error.response.headers['x-warn-message'];
              const infoMessage = error.response.headers['x-info-message'];
              if (errorMessage) {
                setAlert({ type: 'danger', message: errorMessage });
              } else if (warnMessage) {
                setAlert({ type: 'warning', message: warnMessage });
              } else if (infoMessage) {
                setAlert({ type: 'info', message: infoMessage });
              }
            } else {
              console.error('Erro na requisição:', error.response);
            }
          } else {
            console.error('Erro na requisição:', error.message);
          }
          return Promise.reject(error);
        }
      );
      
      setAxiosConfigured(true);
    };

    setupAxiosInterceptors();

    const token = localStorage.getItem('token');
    if (token) {
      setAuth(decodeToken(token));
    }
  }, [navigate]);

  const value = {
    auth,
    setAuth,
    logout,
    hasRequiredRoleEstoque,
    hasRequiredRoleDfe,
    ROLE_ESTOQUE_HIERARCHY,
    ROLE_DFE_HIERARCHY,
    alert,
    setAlert,
    axiosConfigured,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
