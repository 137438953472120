import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import axios from './AxiosInstance';
import { AuthContext } from './AuthContext';

function Test() {
  const { auth, logout, hasRequiredRoleEstoque, hasRequiredRoleDfe, axiosConfigured } = useContext(AuthContext);
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const hasFetched = useRef(false);

  const verifySession = useCallback(async () => {
    try {
      await axios.get('/api/users/verify');
    } catch (error) {
      logout(false);
    }
  }, [logout]);

  useEffect(() => {
    if (axiosConfigured && !hasFetched.current) {
      verifySession();
      hasFetched.current = true;
    }
  }, [axiosConfigured, verifySession]);

  const handleGetMessage = async (type) => {
    let url = '';

    switch (type) {
      case 'verify':
        url = '/api/users/verify';
        break;
      case 'admin':
        url = '/api/users/test/admin';
        break;
      case 'manager':
        url = '/api/users/test/manager';
        break;
      case 'user':
        url = '/api/users/test/user';
        break;
      case 'stock_keeper':
        url = '/api/users/test/stockkeeper';
        break;
      case 'viewer':
        url = '/api/users/test/viewer';
        break;
      default:
        setErrorMessage('Tipo de requisição desconhecido.');
        return;
    }

    try {
      const response = await axios.get(url);
      setMessage(response.data);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setErrorMessage('Sessão expirada. Por favor, faça login novamente.');
      } else if (error.response) {
        setErrorMessage('Falha ao buscar a mensagem. Por favor, tente novamente.');
      } else if (error.request) {
        setErrorMessage('Serviço indisponível. Por favor, tente novamente mais tarde.');
      } else {
        setErrorMessage('Erro desconhecido. Por favor, tente novamente.');
      }
      console.error('Failed to fetch message', error);
    }
  };

  return (
    <div>
      <h2>Página de teste</h2>
      <p><button className="btn btn-primary" onClick={() => handleGetMessage('verify')}>Verificar autenticação</button></p>
      <h3>Teste permissões Estoque</h3>
      <p><button className="btn btn-primary" onClick={() => handleGetMessage('admin')} disabled={!hasRequiredRoleEstoque(auth.roles, 'ROLE_ESTOQUE_ADMIN')}>Verificar perfil Administrador</button></p>
      <p><button className="btn btn-primary" onClick={() => handleGetMessage('manager')} disabled={!hasRequiredRoleEstoque(auth.roles, 'ROLE_ESTOQUE_MANAGER')}>Verificar perfil Gerente</button></p>
      <p><button className="btn btn-primary" onClick={() => handleGetMessage('user')} disabled={!hasRequiredRoleEstoque(auth.roles, 'ROLE_ESTOQUE_USER')}>Verificar perfil Usuário</button></p>
      <p><button className="btn btn-primary" onClick={() => handleGetMessage('stock_keeper')} disabled={!hasRequiredRoleEstoque(auth.roles, 'ROLE_ESTOQUE_STOCK_KEEPER')}>Verificar perfil Operador de Estoque</button></p>
      <p><button className="btn btn-primary" onClick={() => handleGetMessage('viewer')} disabled={!hasRequiredRoleEstoque(auth.roles, 'ROLE_ESTOQUE_VIEWER')}>Verificar perfil Visualizador</button></p>
      <br/>
      <h3>Teste permissões DFE</h3>
      <p><button className="btn btn-primary" onClick={() => handleGetMessage('admin')} disabled={!hasRequiredRoleDfe(auth.roles, 'ROLE_DFE_ADMIN')}>Verificar perfil Administrador</button></p>
      <p><button className="btn btn-primary" onClick={() => handleGetMessage('manager')} disabled={!hasRequiredRoleDfe(auth.roles, 'ROLE_DFE_MANAGER')}>Verificar perfil Gerente</button></p>
      <p><button className="btn btn-primary" onClick={() => handleGetMessage('user')} disabled={!hasRequiredRoleDfe(auth.roles, 'ROLE_DFE_USER')}>Verificar perfil Usuário</button></p>
      <p><button className="btn btn-primary" onClick={() => handleGetMessage('viewer')} disabled={!hasRequiredRoleDfe(auth.roles, 'ROLE_DFE_VIEWER')}>Verificar perfil Visualizador</button></p>
      {message && <p>{message}</p>}
      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
    </div>
  );
}

export default Test;
