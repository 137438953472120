import React, { useState, useEffect, useCallback } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from 'react-pro-sidebar';
import { FaChartLine, FaCog, FaBuilding, FaBars, FaFileInvoiceDollar } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function MySidebar({ setSidebarCollapsed }) {
  const [collapsed, setCollapsed] = useState(false);
  const { collapseSidebar } = useProSidebar();
  
  const handleToggleSidebar = () => {
    const newCollapsedState = !collapsed;
    setCollapsed(newCollapsedState);
    collapseSidebar(newCollapsedState);
    setSidebarCollapsed(newCollapsedState);
  };

  const handleResize = useCallback(() => {
    if (window.innerWidth < 768) {
      setCollapsed(true);
      collapseSidebar(true);
      setSidebarCollapsed(true);
    } else if (window.innerWidth >= 768) {
      setCollapsed(false);
      collapseSidebar(false);
      setSidebarCollapsed(false);
    }
  }, [collapseSidebar, setSidebarCollapsed]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <Sidebar
        collapsed={collapsed}
        backgroundColor="#f8f9fa"
        style={{ height: 'calc(100% - 56px)', position: 'fixed', top: '56px', left: 0 }}
      >
        <Menu iconShape="circle">
          <MenuItem icon={<FaBars />} onClick={handleToggleSidebar}>Menu</MenuItem>
          <MenuItem icon={<FaChartLine />} component={<Link to="/home" />}>
            Dashboard
          </MenuItem>
          <SubMenu label="Documentos fiscais" icon={<FaFileInvoiceDollar />}>
            <MenuItem>Notas Fiscais - Entrada</MenuItem>
            <MenuItem>Notas Fiscais - Saída</MenuItem>
          </SubMenu>
          {/*<SubMenu label="Controle de estoque" icon={<MdShelves />}>
            <MenuItem>Entradas</MenuItem>
            <MenuItem>Saídas</MenuItem>
            <MenuItem>Transferências</MenuItem>
            <MenuItem>Inventário</MenuItem>
            <MenuItem>Pedidos</MenuItem>
          </SubMenu>*/}
          <SubMenu label="Cadastros" icon={<FaBuilding />}>
            <MenuItem component={<Link to="/customers" />}>Clientes</MenuItem>
            <MenuItem component={<Link to="/suppliers" />}>Fornecedores</MenuItem>
            <MenuItem component={<Link to="/tax-rules" />}>Regras Fiscais</MenuItem>
            <MenuItem component={<Link to="/items/groups" />}>Grupos de Itens</MenuItem>
            <MenuItem component={<Link to="/sales-category" />}>Categorias de Vendas</MenuItem>
            <MenuItem component={<Link to="/items" />}>Itens</MenuItem>
            <MenuItem component={<Link to="/stock-locations" />}>Locais de Estoque</MenuItem>
            <MenuItem component={<Link to="/unit-measurements" />}>Unidades de Medidas</MenuItem>
          </SubMenu>
          <SubMenu label="Administração" icon={<FaCog />}>
            <MenuItem component={<Link to="/company-settings" />}>Conta/Loja</MenuItem>
            <MenuItem component={<Link to="/users" />}>Gerenciar Usuários</MenuItem>
            {/*<MenuItem>Informações de Pagamento</MenuItem>
            <MenuItem>Webhooks</MenuItem>
            <MenuItem>APIs de Integração</MenuItem>
            <MenuItem>Importação de Dados</MenuItem>
            <MenuItem>Exportação de Dados</MenuItem>*/}
          </SubMenu>
        </Menu>
      </Sidebar>
    </div>
  );
}

export default MySidebar;
