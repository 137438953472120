import React, { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../components/AuthContext';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faSignOutAlt, faCircleUser } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/images/logo-transparent.webp';
import '../assets/styles/NavBar.css'; // Arquivo CSS para estilização adicional

function Navbar() {
  const [showUserMenu, setShowUserMenu] = React.useState(false);
  const { auth, logout } = useContext(AuthContext);
  const userMenuRef = useRef(null);
  const location = useLocation();
  const isLoginRoute = location.pathname === '/login' || location.pathname === '/register';
  const isCompanySelectionRoutte =  location.pathname === '/company/selection'

  const toggleUserMenu = () => {
    setShowUserMenu(!showUserMenu);
  };

  const closeUserMenu = () => {
    setShowUserMenu(false);
  };

  const handleLogout = () => {
    logout(true);
    window.location.href = '/login';
    closeUserMenu();
  };

  const handleProfile = () => {
    window.location.href = '/profile';
    closeUserMenu();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        closeUserMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [userMenuRef]);

  return (
    <nav className="navbar navbar-expand-lg navbar-custom">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="Simpletize" width="35" height="auto" className="d-inline-block align-top" />
          Simpletize
        </Link>
        {!isLoginRoute && (
          <div className="d-flex align-items-center">
            <div className="user-menu" ref={userMenuRef}>
              <FontAwesomeIcon
                icon={faCircleUser}
                size="2x"
                className="user-icon"
                onClick={toggleUserMenu}
                style={{ cursor: 'pointer' }}
              />
              {showUserMenu && (
                <div className="user-menu-dropdown">
                  <p><strong>{auth.userName}</strong></p>
                  <p>{auth.loginUser}</p>
                  {!isCompanySelectionRoutte && (
                    <div className="dropdown-item" onClick={handleProfile} style={{ cursor: 'pointer' }}>
                      <FontAwesomeIcon icon={faCog} /> Configuração do perfil
                    </div>
                  )}
                  <div className="dropdown-item" onClick={handleLogout} style={{ cursor: 'pointer' }}>
                    <FontAwesomeIcon icon={faSignOutAlt} /> Sair
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
