import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import axios from './AxiosInstance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import InfoPopover from './InfoPopover';
import ReactInputMask from 'react-input-mask';
import AsyncSelect2 from './AsyncSelect2';

function TaxRuleEdit() {
    const { setAlert, axiosConfigured } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        name: '',
        additionalInformation: '',
        active: 'true',
        csosn: '',
        icmsOrigin: '',
        icmsCreditPercentage: '',
        icmsStModality: '',
        icmsStAliquota: '',
        icmsStBaseReduction: '',
        icmsStMarginValueAdded: '',
        fcpStPercentage: '',
        icmsEffectivePercentage: '',
        fcpRetainedPercentage: '',
        icmsStRetainedPercentage: '',
        icmsEffectiveBaseReduction: '',
        pisAliquota: '',
        pisSituation: '',
        cofinsAliquota: '',
        cofinsSituation: '',
        ipiAliquota: '',
        ipiCode: '',
        cnpjProdutor: '',
        codigoSelo: '',
        quantidadeSelo: '',
        codigoBeneficioFiscal: '',
        cfop: null,
        ncm: null,
        cest: null
    });
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [activeTab, setActiveTab] = useState('dadosGerais');
    const [selectedCfop, setSelectedCfop] = useState(null);
    const [selectedCest, setSelectedCest] = useState(null);
    const [selectedNcm, setSelectedNcm] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const { taxRule: initialTaxRule, searchTerm, page, rows, sortField, sortOrder } = location.state || {};
    const [isFormChanged, setIsFormChanged] = useState(false);
    const hasFetchedData = useRef(false);
    const [taxRule, setTaxRule] = useState(initialTaxRule || {});
    const [inProp, setInProp] = useState(false);
    const loadingTimeout = useRef(null);

    const initializeForm = useCallback((taxRule) => {
        setTaxRule(taxRule);
        setFormData({
            name: taxRule.name,
            additionalInformation: taxRule.additionalInformation,
            active: taxRule.active ? 'true' : 'false',
            csosn: extractCodeFromDescription(taxRule.csosn),
            icmsOrigin: extractCodeFromDescription(taxRule.icmsOrigin),
            icmsCreditPercentage: taxRule.icmsCreditPercentage || '',
            icmsStModality: taxRule.icmsStModality || '',
            icmsStAliquota: taxRule.icmsStAliquota || '',
            icmsStBaseReduction: taxRule.icmsStBaseReduction || '',
            icmsStMarginValueAdded: taxRule.icmsStMarginValueAdded || '',
            fcpStPercentage: taxRule.fcpStPercentage || '',
            icmsEffectivePercentage: taxRule.icmsEffectivePercentage || '',
            fcpRetainedPercentage: taxRule.fcpRetainedPercentage || '',
            icmsStRetainedPercentage: taxRule.icmsStRetainedPercentage || '',
            icmsEffectiveBaseReduction: taxRule.icmsEffectiveBaseReduction || '',
            pisAliquota: taxRule.pisAliquota || '',
            pisSituation: extractCodeFromDescription(taxRule.pisSituation),
            cofinsAliquota: taxRule.cofinsAliquota || '',
            cofinsSituation: extractCodeFromDescription(taxRule.cofinsSituation),
            ipiAliquota: taxRule.ipiAliquota || '',
            ipiCode: taxRule.ipiCode || '',
            cnpjProdutor: taxRule.cnpjProdutor || '',
            codigoSelo: taxRule.codigoSelo || '',
            quantidadeSelo: taxRule.quantidadeSelo || '',
            codigoBeneficioFiscal: taxRule.codigoBeneficioFiscal || '',
            cfop: taxRule.cfop || null,
            ncm: taxRule.ncm || null,
            cest: taxRule.cest || null
        });
        setSelectedCfop(taxRule.cfop ? {
            label: `${taxRule.cfop.cfopCode} - ${taxRule.cfop.description}`,
            value: taxRule.cfop.cfopCode,
            name: taxRule.cfop.description
        } : null);
        setSelectedCest(taxRule.cest ? {
            label: `${taxRule.cest.cestCode} - ${taxRule.cest.description}`,
            value: taxRule.cest.cestCode,
            name: taxRule.cest.description
        } : null);
        setSelectedNcm(taxRule.ncm ? {
            label: `${taxRule.ncm.ncmCode} - ${taxRule.ncm.description}`,
            value: taxRule.ncm.ncmCode,
            name: taxRule.ncm.description
        } : null);
    }, []);

    const loadTaxRule = useCallback(async () => {
        try {
            const response = await axios.get(`/api/tax-rules/${id}`);
            clearTimeout(loadingTimeout.current);
            initializeForm(response.data);
            setShowForm(true);
        } catch (error) {
            console.error('Erro ao carregar regra fiscal:', error);
            if (error.response && error.response.status === 404) {
                navigate('/tax-rules', { state: { searchTerm, page, rows, sortField, sortOrder } });
            }
        } finally {
            setLoading(false);
        }
    }, [id, navigate, searchTerm, page, rows, sortField, sortOrder, initializeForm]);

    useEffect(() => {
        if (axiosConfigured && !hasFetchedData.current && id !== 'new') {
            loadingTimeout.current = setTimeout(() => {
                setLoading(true);
            }, 500);
            loadTaxRule();
            hasFetchedData.current = true;
        } else if (id === 'new') {
            clearTimeout(loadingTimeout.current);
            setLoading(false);
            setShowForm(true);
        }
    }, [axiosConfigured, id, loadTaxRule]);

    useEffect(() => {
        setInProp(true);
    }, []);

    const extractCodeFromDescription = (description) => {
        if (description) {
            const codeMatch = description.match(/^\d+/);
            return codeMatch ? codeMatch[0] : '';
        }
        return '';
    };

    const handleNumber = (e) => {
        if (isNaN(e.key) && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight' && e.key !== 'Tab') {
            e.preventDefault();
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Função auxiliar para checar se o campo é numérico e está relacionado a percentuais ou valores numéricos
        const isNumericField = (fieldName) => {
            return (
                fieldName.includes('Aliquota') || 
                fieldName.includes('Percentage') || 
                fieldName.includes('BaseReduction') || 
                fieldName.includes('Reduction') || 
                fieldName.includes('MarginValueAdded')
            );
        };
    
        // Limitar o valor de 'quantidadeSelo' a 12 dígitos
        if (name === 'quantidadeSelo' && value.length > 12) {
            return;
        }
    
        // Se for um campo numérico e o valor estiver fora do intervalo 0-100, retornamos sem alterar
        if (isNumericField(name) && (value < 0 || value > 100)) {
            return;
        }
    
        // Se o campo for 'active', garantimos que o valor seja convertido para booleano
        let parsedValue;
        if (name === 'active') {
            parsedValue = value === 'true'; // Converte para booleano
        } else if (!isNaN(parseFloat(value)) && isNumericField(name)) { // Se o campo for numérico
            parsedValue = parseFloat(value); // Converte para número
        } else {
            if (isNaN(value) && isNumericField(name)) {
                parsedValue = "";
            } else {
                parsedValue = value;
            }
        }
    
        setFormData((prevData) => {
            const isChanged = prevData[name] !== parsedValue;
            if (isChanged) {
                setIsFormChanged(true);
            }
    
            const updatedData = { ...prevData, [name]: parsedValue };
    
            // Limpar campos desnecessários se o campo 'csosn' for alterado
            if (name === 'csosn') {
                return clearUnusedFields(updatedData, parsedValue);
            }
    
            return updatedData;
        });
    };

    const handleCfopChange = (selectedOption) => {
        const selectedCfop = selectedOption ? {
            cfopCode: selectedOption.value,
            description: selectedOption.description
        } : null;
        
        setSelectedCfop(selectedOption);

        setFormData((prevData) => {
            const isChanged = prevData.cfop !== selectedCfop;
            if (isChanged) {
                setIsFormChanged(true);
            }
            return { ...prevData, cfop: selectedCfop };
        });
    };

    const handleNcmChange = (selectedOption) => {
        const selectedNcm = selectedOption ? {
            ncmCode: selectedOption.value,
            description: selectedOption.description
        } : null;
        
        setSelectedNcm(selectedOption);

        setFormData((prevData) => {
            const isChanged = prevData.ncm !== selectedNcm;
            if (isChanged) {
                setIsFormChanged(true);
            }
            return { ...prevData, ncm: selectedNcm };
        });
    };

    const handleCestChange = (selectedOption) => {
        const selectedCest = selectedOption ? {
            cestCode: selectedOption.value,
            description: selectedOption.description
        } : null;
        
        setSelectedCest(selectedOption);

        setFormData((prevData) => {
            const isChanged = prevData.cest !== selectedCest;
            if (isChanged) {
                setIsFormChanged(true);
            }
            return { ...prevData, cest: selectedCest };
        });
    };

    const clearUnusedFields = (data, csosn) => {
        const fieldsToKeep = getFieldsByCsosn(csosn);
        const allFields = [
            'icmsCreditPercentage',
            'icmsStModality',
            'icmsStAliquota',
            'icmsStBaseReduction',
            'icmsStMarginValueAdded',
            'fcpStPercentage',
            'icmsEffectivePercentage',
            'fcpRetainedPercentage',
            'icmsStRetainedPercentage',
            'icmsEffectiveBaseReduction'
        ];
        const fieldsToClear = allFields.filter(field => !fieldsToKeep.includes(field));
        const clearedData = { ...data };
        fieldsToClear.forEach(field => {
            clearedData[field] = '';
        });
        return clearedData;
    };

    const areRequiredFieldsFilled = () => {
        const requiredFields = ['name', 'csosn', 'icmsOrigin', 'pisSituation', 'cofinsSituation'];
        const csosnRequiredFields = getFieldsByCsosn(formData.csosn);
        const allRequiredFields = [...requiredFields, ...csosnRequiredFields];
    
        // Check if all required fields are filled
        const allFieldsValid = allRequiredFields.every((field) => {
            const value = formData[field];
            if (typeof value === 'string') {
                return value.trim() !== '';
            } else {
                return !!value;
            }
        });
    
        // Verify if percentage fields have valid values
        const percentFields = [
            'icmsCreditPercentage', 
            'icmsStAliquota', 
            'fcpStPercentage', 
            'icmsEffectivePercentage', 
            'fcpRetainedPercentage', 
            'icmsStRetainedPercentage', 
            'icmsEffectiveBaseReduction', 
            'ipiAliquota', 
            'pisAliquota', 
            'cofinsAliquota', 
            'icmsStBaseReduction', 
            'icmsStMarginValueAdded'
        ];
        
        const allPercentFieldsValid = percentFields.every((field) => {
            const value = parseFloat(formData[field]);
            return isNaN(value) || (value >= 0 && value <= 100);
        });
    
        return allFieldsValid && allPercentFieldsValid;
    };

    const getFieldsByCsosn = (csosnValue) => {
        switch (csosnValue) {
            case '101':
                return ['icmsCreditPercentage'];
            case '201':
                return ['icmsCreditPercentage', 'icmsStModality', 'icmsStAliquota', 'icmsStBaseReduction', 'icmsStMarginValueAdded', 'fcpStPercentage'];
            case '202':
            case '203':
                return ['icmsStModality', 'icmsStAliquota', 'icmsStBaseReduction', 'icmsStMarginValueAdded', 'fcpStPercentage'];
            case '500':
                return ['icmsEffectivePercentage', 'fcpRetainedPercentage', 'icmsStRetainedPercentage', 'icmsEffectiveBaseReduction'];
            case '900':
                return ['icmsCreditPercentage', 'icmsStModality', 'icmsStAliquota', 'icmsStBaseReduction', 'icmsStMarginValueAdded', 'fcpStPercentage'];
            default:
                return [];
        }
    };

    function removeFormatting(value) {
        if (!value) {
            return '';
        }
        return value.replace(/[^\d]/g, '');
    }

    const handleSave = async () => {
        setIsFormChanged(false);

        const dataToSend = {
            ...formData,
            cfop: selectedCfop ? {
                cfopCode: selectedCfop.value,
                description: selectedCfop.label.replace(/^[^-]*-\s*/, '')
            } : null,
            ncm: selectedNcm ? {
                ncmCode: selectedNcm.value,
                description: selectedNcm.label.replace(/^[^-]*-\s*/, '')
            } : null,
            cest: selectedCest ? {
                cestCode: selectedCest.value,
                description: selectedCest.label.replace(/^[^-]*-\s*/, '')
            } : null
        };

        dataToSend.cnpjProdutor = removeFormatting(dataToSend.cnpjProdutor);

        try {
            if (taxRule.id) {
                await axios.put(`/api/tax-rules/${taxRule.id}`, dataToSend);
                setAlert({ type: 'success', message: 'Regra fiscal editada com sucesso.' });
            } else {
                await axios.post('/api/tax-rules', dataToSend);
                setAlert({ type: 'success', message: 'Regra fiscal adicionada com sucesso.' });
            }
            setInProp(false);
            setTimeout(() => {
                navigate('/tax-rules', { state: { searchTerm, page, rows, sortField, sortOrder } });
            }, 300);
        } catch (error) {
            setIsFormChanged(true);
            console.error('Erro ao salvar regra fiscal', error);
        }
    };

    const handleCancel = () => {
        setInProp(false);
        setTimeout(() => {
            navigate('/tax-rules', { state: { searchTerm, page, rows, sortField, sortOrder } });
        }, 300);
    };

    const renderGeneralData = () => (
        <div>
            <div className="mb-4 p-3 border rounded"><h5 style={{marginBottom: 1 + 'em'}}>Informações Básicas</h5>
                <div className="mb-3">
                    <label className="form-label">Nome <span className="text-danger">*</span></label>
                    <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={formData.name || ''}
                        onChange={handleInputChange}
                        maxLength={60}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">
                        CFOP
                        <InfoPopover
                            content={
                                <div style={{ textAlign: 'justify' }}>
                                    O CFOP identifica a natureza da circulação da mercadoria ou a prestação de serviço. Ele define se a operação envolve compra, venda, transferência, devolução, importação, exportação, entre outros. O código CFOP é essencial para o cálculo correto dos tributos.
                                </div>
                            }
                        />{'\u00A0'}<span className="text-danger">*</span>
                    </label>
                    <AsyncSelect2
                        url="/api/cfops"
                        value={selectedCfop}
                        onChange={handleCfopChange}
                        valueField="cfopCode"
                        labelField="description"
                        placeholder="Selecione o CFOP"
                        initialOptions={selectedCfop ? [selectedCfop] : []}
                        labelFormatter={(item) => `${item.cfopCode} - ${item.description}`}
                        showClear
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">
                        NCM
                        <InfoPopover
                                content={
                                    <div style={{ textAlign: 'justify' }}>
                                        O NCM é um código utilizado para classificar mercadorias, padronizado para os países membros do Mercosul. Ele serve para identificar a natureza do produto em operações fiscais, como vendas e importações, e é fundamental para determinar a tributação de cada produto.
                                    </div>
                                }
                            />{'\u00A0'}<span className="text-danger">*</span>
                    </label>
                    <AsyncSelect2
                        url="/api/ncms"
                        value={selectedNcm}
                        onChange={handleNcmChange}
                        valueField="ncmCode"
                        labelField="description"
                        placeholder="Selecione o NCM"
                        initialOptions={formData.ncm ? [formData.ncm] : []}
                        labelFormatter={(item) => `${item.ncmCode} - ${item.description}`}
                        showClear
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">
                        CEST
                        <InfoPopover
                                content={
                                    <div style={{ textAlign: 'justify' }}>
                                        O CEST é um código que uniformiza e identifica as mercadorias e bens passíveis de sujeição aos regimes de substituição tributária e de antecipação do recolhimento do ICMS. Ele é obrigatório em operações com produtos sujeitos à substituição tributária.
                                    </div>
                                }
                            />
                    </label>
                    <AsyncSelect2
                        url="/api/cests"
                        value={selectedCest}
                        onChange={handleCestChange}
                        valueField="cestCode"
                        labelField="description"
                        placeholder="Selecione o CEST"
                        initialOptions={formData.cest ? [formData.cest] : []}
                        labelFormatter={(item) => `${item.cestCode} - ${item.description}`}
                        showClear
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Informações Adicionais</label>
                    <textarea
                        className="form-control"
                        name="additionalInformation"
                        value={formData.additionalInformation || ''}
                        onChange={handleInputChange}
                        rows="4"
                        maxLength={2000}
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">
                        Ativo
                        <InfoPopover
                                content={
                                    <div style={{ textAlign: 'justify' }}>
                                        Se desativado, não permitirá o uso em dessa regra em novas categorias de vendas.
                                    </div>
                                }
                            />{'\u00A0'}<span className="text-danger">*</span>
                    </label>
                    <select 
                        className="form-select"
                        name="active"
                        value={formData.active}
                        onChange={handleInputChange}
                    >
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                    </select>
                </div>
            </div>
            <div className="mb-4 p-3 border rounded"><h5 style={{marginBottom: 1 + 'em'}}>Produtos Especiais</h5>
                <div className="row mb-3">
                    <div className="col-md-6">
                        <label className="form-label">
                            CNPJ do Produtor
                            <InfoPopover
                                content={
                                    <div style={{ textAlign: 'justify' }}>
                                        O CNPJ do Produtor é o número de registro no Cadastro Nacional da Pessoa Jurídica (CNPJ) do produtor rural ou empresa responsável pela produção de mercadorias em operações de venda ou transferência. Esse campo é obrigatório em operações que envolvem produtores rurais.
                                    </div>
                                }
                            />
                        </label>
                        <ReactInputMask mask="99.999.999/9999-99" value={formData.cnpjProdutor || ''} onChange={handleInputChange}>
                            {(inputProps) => <input {...inputProps} type="text" className="form-control" name="cnpjProdutor" required />}
                        </ReactInputMask>
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">
                            Código do Selo
                            <InfoPopover
                                content={
                                    <div style={{ textAlign: 'justify' }}>
                                        O Código do Selo é utilizado para identificar o selo de controle fiscal de produtos sujeitos à regulamentação especial, como bebidas e produtos controlados. Esse código é exigido pela legislação tributária para garantir o controle e a procedência desses produtos.
                                    </div>
                                }
                            />
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            name="codigoSelo"
                            value={formData.codigoSelo || ''}
                            onChange={handleInputChange}
                            maxLength={60}
                        />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-6">
                        <label className="form-label">
                            Quantidade de Selos
                            <InfoPopover
                                content={
                                    <div style={{ textAlign: 'justify' }}>
                                        Este campo indica a quantidade de selos de controle fiscal aplicados ao produto. Ele é necessário quando o produto exige a aplicação de selos fiscais para garantir a legalidade da mercadoria e o cumprimento das obrigações tributárias.
                                    </div>
                                }
                            />
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            name="quantidadeSelo"
                            value={formData.quantidadeSelo || ''}
                            onChange={handleInputChange}
                            max="999999999999"
                        />
                    </div>
                    <div className="col-md-6">
                        <label className="form-label">
                            Código do Benefício Fiscal
                            <InfoPopover
                                content={
                                    <div style={{ textAlign: 'justify' }}>
                                        O Código do Benefício Fiscal informa a existência de benefícios fiscais aplicáveis à operação, como isenções, reduções de base de cálculo ou diferimentos do ICMS. Esse código deve ser preenchido conforme a legislação estadual ou federal que rege o benefício fiscal aplicável.
                                    </div>
                                }
                            />
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            name="codigoBeneficioFiscal"
                            value={formData.codigoBeneficioFiscal || ''}
                            onChange={handleInputChange}
                            maxLength={10}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    const renderICMSFields = () => {
        const fields = getFieldsByCsosn(formData.csosn);
        return (
            <div>
                <div class="mb-4 p-3 border rounded"><h5 style={{marginBottom: 1 + 'em'}}>Configuração do ICMS</h5>
                    <div className="mb-3">
                        <label className="form-label">
                            CSOSN
                            <InfoPopover
                                content={
                                    <div>
                                        <p><strong>Código de Situação da Operação do Simples Nacional:</strong></p>
                                        <ul>
                                            <li><strong>101</strong> - Tributada com permissão de crédito: Utilizada quando a operação permite a apropriação de crédito de ICMS.</li>
                                            <li><strong>102</strong> - Tributada sem permissão de crédito: Quando a operação não permite a apropriação de crédito.</li>
                                            <li><strong>201</strong> - Tributada com permissão de crédito e cobrança ICMS-ST: Utilizada em operações sujeitas ao regime de Substituição Tributária com permissão de crédito.</li>
                                            <li><strong>202</strong> - Tributada sem permissão de crédito e cobrança ICMS-ST: Quando a operação é tributada sob ICMS-ST sem direito a crédito.</li>
                                            <li><strong>203</strong> - Tributação específica para faixas de receita bruta.</li>
                                            <li><strong>300</strong> - Imune: Quando a mercadoria ou serviço é imune ao ICMS.</li>
                                            <li><strong>400</strong> - Não tributada: Quando a operação não é tributada pelo ICMS.</li>
                                            <li><strong>500</strong> - ICMS cobrado anteriormente por ST: Operações em que o ICMS foi cobrado em etapa anterior por substituição tributária.</li>
                                            <li><strong>900</strong> - Outros: Usado para situações não especificadas nos códigos anteriores.</li>
                                        </ul>
                                    </div>
                                }
                            />{'\u00A0'}<span className="text-danger">*</span>
                        </label>
                        <select
                            className="form-select"
                            name="csosn"
                            value={formData.csosn || ''}
                            onChange={handleInputChange}
                        >
                            <option value="">Selecione</option>
                            <option value="101">101 - Tributada com permissão de crédito</option>
                            <option value="102">102 - Tributada sem permissão de crédito</option>
                            <option value="201">201 - Tributada com permissão de crédito e cobrança ICMS-ST</option>
                            <option value="202">202 - Tributada sem permissão de crédito e cobrança ICMS-ST</option>
                            <option value="203">203 - Tributada para faixa receita bruta e cobrança ICMS-ST</option>
                            <option value="300">300 - Imune</option>
                            <option value="400">400 - Não tributada</option>
                            <option value="500">500 - ICMS cobrado anteriormente por ST</option>
                            <option value="900">900 - Outros</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">
                            Origem do ICMS 
                            <InfoPopover
                                content={
                                    <div style={{ textAlign: 'justify' }}>
                                        Este campo indica a procedência da mercadoria e é essencial para o cálculo correto do imposto.
                                    </div>
                                }
                            />{'\u00A0'}<span className="text-danger">*</span>
                        </label>
                        <select
                            className="form-select"
                            name="icmsOrigin"
                            value={formData.icmsOrigin || ''}
                            onChange={handleInputChange}
                        >
                            <option value="">Selecione</option>
                            <option value="0">0 - Nacional</option>
                            <option value="1">1 - Estrangeira importação direta</option>
                            <option value="2">2 - Estrangeira adquirida no mercado interno</option>
                            <option value="3">3 - Nacional mercadoria ou bem conteúdo importação superior 40%</option>
                            <option value="4">4 - Nacional produção em conformidade com processos produtivos básicos</option>
                            <option value="5">5 - Nacional mercadoria ou bem conteúdo importação inferior 40%</option>
                            <option value="6">6 - Estrangeira importação direta sem similar nacional constante em lista Camex</option>
                            <option value="7">7 - Estrangeira adquirida mercado interno sem similar nacional constante em lista Camex</option>
                            <option value="8">8 - Nacional mercadoria ou bem conteúdo importação superior 70%</option>
                        </select>
                    </div>
                    {fields.includes('icmsCreditPercentage') && (
                        <div className="mb-3">
                            <label className="form-label">Percentual de Crédito de ICMS <span className="text-danger">*</span></label>
                            <input
                                type="number"
                                className="form-control"
                                name="icmsCreditPercentage"
                                value={formData.icmsCreditPercentage || ''}
                                onChange={handleInputChange}
                                onBlur={(e) => {
                                    // Formata para 2 casas decimais ao sair do campo
                                    const formattedValue = parseFloat(e.target.value).toFixed(2);
                                    handleInputChange({ target: { name: e.target.name, value: formattedValue } });
                                }}
                                step="0.01"
                                required
                                min="0"
                                max="100"
                            />
                        </div>
                    )}
                    {fields.includes('icmsStModality') && (
                        <div className="mb-3">
                            <label className="form-label">Modalidade da Base de Cálculo do ICMS-ST <span className="text-danger">*</span></label>
                            <select
                                className="form-select"
                                name="icmsStModality"
                                value={formData.icmsStModality || ''}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Selecione</option>
                                <option value="0">Preço Tabelado</option>
                                <option value="1">Lista Negativa</option>
                                <option value="2">Lista Positiva</option>
                                <option value="3">Lista Neutra</option>
                                <option value="4">Margem Valor Agregado</option>
                                <option value="5">Pauta</option>
                                <option value="6">Valor Operação</option>
                            </select>
                        </div>
                    )}
                    {fields.includes('icmsStAliquota') && (
                        <div className="mb-3">
                            <label className="form-label">Alíquota do ICMS-ST <span className="text-danger">*</span></label>
                            <input
                                type="number"
                                className="form-control"
                                name="icmsStAliquota"
                                value={formData.icmsStAliquota || ''}
                                onChange={handleInputChange}
                                onBlur={(e) => {
                                    // Formata para 2 casas decimais ao sair do campo
                                    const formattedValue = parseFloat(e.target.value).toFixed(2);
                                    handleInputChange({ target: { name: e.target.name, value: formattedValue } });
                                }}
                                step="0.01"
                                required
                                min="0"
                                max="100"
                            />
                        </div>
                    )}
                    {fields.includes('icmsStBaseReduction') && (
                        <div className="mb-3">
                            <label className="form-label">Percentual de Redução da Base do ICMS-ST <span className="text-danger">*</span></label>
                            <input
                                type="number"
                                className="form-control"
                                name="icmsStBaseReduction"
                                value={formData.icmsStBaseReduction || ''}
                                onChange={handleInputChange}
                                onBlur={(e) => {
                                    // Formata para 2 casas decimais ao sair do campo
                                    const formattedValue = parseFloat(e.target.value).toFixed(2);
                                    handleInputChange({ target: { name: e.target.name, value: formattedValue } });
                                }}
                                step="0.01"
                                required
                                min="0"
                                max="100"
                            />
                        </div>
                    )}
                    {fields.includes('icmsStMarginValueAdded') && (
                        <div className="mb-3">
                            <label className="form-label">Percentual de Margem de Valor Agregado (MVA) <span className="text-danger">*</span></label>
                            <input
                                type="number"
                                className="form-control"
                                name="icmsStMarginValueAdded"
                                value={formData.icmsStMarginValueAdded || ''}
                                onChange={handleInputChange}
                                onBlur={(e) => {
                                    // Formata para 2 casas decimais ao sair do campo
                                    const formattedValue = parseFloat(e.target.value).toFixed(2);
                                    handleInputChange({ target: { name: e.target.name, value: formattedValue } });
                                }}
                                step="0.01"
                                required
                                min="0"
                                max="100"
                            />
                        </div>
                    )}
                    {fields.includes('fcpStPercentage') && (
                        <div className="mb-3">
                            <label className="form-label">Percentual do FCP <span className="text-danger">*</span></label>
                            <input
                                type="number"
                                className="form-control"
                                name="fcpStPercentage"
                                value={formData.fcpStPercentage || ''}
                                onChange={handleInputChange}
                                onBlur={(e) => {
                                    // Formata para 2 casas decimais ao sair do campo
                                    const formattedValue = parseFloat(e.target.value).toFixed(2);
                                    handleInputChange({ target: { name: e.target.name, value: formattedValue } });
                                }}
                                step="0.01"
                                required
                                min="0"
                                max="100"
                            />
                        </div>
                    )}
                    {fields.includes('icmsEffectivePercentage') && (
                        <div className="mb-3">
                            <label className="form-label">Percentual Efetivo do ICMS <span className="text-danger">*</span></label>
                            <input
                                type="number"
                                className="form-control"
                                name="icmsEffectivePercentage"
                                value={formData.icmsEffectivePercentage || ''}
                                onChange={handleInputChange}
                                onBlur={(e) => {
                                    // Formata para 2 casas decimais ao sair do campo
                                    const formattedValue = parseFloat(e.target.value).toFixed(2);
                                    handleInputChange({ target: { name: e.target.name, value: formattedValue } });
                                }}
                                step="0.01"
                                required
                                min="0"
                                max="100"
                            />
                        </div>
                    )}
                    {fields.includes('fcpRetainedPercentage') && (
                        <div className="mb-3">
                            <label className="form-label">Percentual Retido do FCP <span className="text-danger">*</span></label>
                            <input
                                type="number"
                                className="form-control"
                                name="fcpRetainedPercentage"
                                value={formData.fcpRetainedPercentage || ''}
                                onChange={handleInputChange}
                                onBlur={(e) => {
                                    // Formata para 2 casas decimais ao sair do campo
                                    const formattedValue = parseFloat(e.target.value).toFixed(2);
                                    handleInputChange({ target: { name: e.target.name, value: formattedValue } });
                                }}
                                step="0.01"
                                required
                                min="0"
                                max="100"
                            />
                        </div>
                    )}
                    {fields.includes('icmsStRetainedPercentage') && (
                        <div className="mb-3">
                            <label className="form-label">Percentual Retido do ICMS-ST <span className="text-danger">*</span></label>
                            <input
                                type="number"
                                className="form-control"
                                name="icmsStRetainedPercentage"
                                value={formData.icmsStRetainedPercentage || ''}
                                onChange={handleInputChange}
                                onBlur={(e) => {
                                    // Formata para 2 casas decimais ao sair do campo
                                    const formattedValue = parseFloat(e.target.value).toFixed(2);
                                    handleInputChange({ target: { name: e.target.name, value: formattedValue } });
                                }}
                                step="0.01"
                                required
                                min="0"
                                max="100"
                            />
                        </div>
                    )}
                    {fields.includes('icmsEffectiveBaseReduction') && (
                        <div className="mb-3">
                            <label className="form-label">Redução Efetiva da Base do ICMS <span className="text-danger">*</span></label>
                            <input
                                type="number"
                                className="form-control"
                                name="icmsEffectiveBaseReduction"
                                value={formData.icmsEffectiveBaseReduction || ''}
                                onChange={handleInputChange}
                                onBlur={(e) => {
                                    // Formata para 2 casas decimais ao sair do campo
                                    const formattedValue = parseFloat(e.target.value).toFixed(2);
                                    handleInputChange({ target: { name: e.target.name, value: formattedValue } });
                                }}
                                step="0.01"
                                required
                                min="0"
                                max="100"
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const renderPISFields = () => (
        <div>
            <div class="mb-4 p-3 border rounded"><h5 style={{marginBottom: 1 + 'em'}}>Configuração do PIS</h5>
                <div className="mb-3">
                    <label className="form-label">Alíquota</label>
                    <input
                        type="number"
                        className="form-control"
                        name="pisAliquota"
                        value={formData.pisAliquota || ''}
                        onChange={handleInputChange}
                        onBlur={(e) => {
                            // Formata para 2 casas decimais ao sair do campo
                            const formattedValue = parseFloat(e.target.value).toFixed(2);
                            handleInputChange({ target: { name: e.target.name, value: formattedValue } });
                        }}
                        step="0.01"
                        min="0"
                        max="100"
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Situação Tributária <span className="text-danger">*</span></label>
                    <select
                        className="form-select"
                        name="pisSituation"
                        value={formData.pisSituation || ''}
                        onChange={handleInputChange}
                        required
                    >
                        <option value="">Selecione</option>
                        <option value="01">01 - Operação tributável cumulativo/não cumulativo</option>
                        <option value="02">02 - Operação tributável alíquota diferenciada</option>
                        <option value="03">03 - Operação tributável quantidade vendida por alíquota por unidade produto</option>
                        <option value="04">04 - Operação tributável monofásica alíquota zero</option>
                        <option value="05">05 - Operação tributável ST</option>
                        <option value="06">06 - Operação tributável alíquota zero</option>
                        <option value="07">07 - Operação isenta de contribuição</option>
                        <option value="08">08 - Operação sem incidência de contribuição</option>
                        <option value="09">09 - Operação com suspensão de contribuição</option>
                        <option value="49">49 - Outras operações de saída</option>
                        <option value="50">50 - Operação direito crédito vinculada exclusivamente receita tributada mercado interno</option>
                        <option value="51">51 - Operação direito crédito vinculada exclusivamente receita não tributada mercado interno</option>
                        <option value="52">52 - Operação direito crédito vinculada exclusivamente receita exportação</option>
                        <option value="53">53 - Operação direito crédito vinculada receitas tributada e não tributada mercado interno</option>
                        <option value="54">54 - Operação direito crédito vinculada receitas tributadas no mercado interno exportação</option>
                        <option value="55">55 - Operação direito crédito vinculada receitas não tributada no mercado interno exportação</option>
                        <option value="56">56 - Operação direito crédito vinculada receitas tributadas e não tributadas mercado interno exportação</option>
                        <option value="60">60 - Crédito presumido operação aquisição vinculada exclusivamente receita tributada mercado interno</option>
                        <option value="61">61 - Crédito presumido operação aquisição vinculada exclusivamente à receita não tributada mercado interno</option>
                        <option value="62">62 - Crédito presumido operação aquisição vinculada exclusivamente receita exportação</option>
                        <option value="63">63 - Crédito presumido operação aquisição vinculada receitas tributadas mercado interno</option>
                        <option value="64">64 - Crédito presumido operação aquisição vinculada receitas tributadas mercado interno exportação</option>
                        <option value="65">65 - Crédito presumido operação aquisição vinculada receitas não tributadas mercado interno exportação</option>
                        <option value="66">66 - Crédito presumido operação aquisição vinculada receitas tributadas e não tributadas mercado interno exportação</option>
                        <option value="67">67 - Crédito presumido outras operações</option>
                        <option value="70">70 - Operação aquisição sem direito crédito</option>
                        <option value="71">71 - Operação aquisição com isenção</option>
                        <option value="72">72 - Operação aquisição com suspensão</option>
                        <option value="73">73 - Operação aquisição alíquota zero</option>
                        <option value="74">74 - Operação aquisição sem incidência de contribuição</option>
                        <option value="75">75 - Operação aquisição por substituição tributária</option>
                        <option value="98">98 - Outras operações de entrada</option>
                        <option value="99">99 - Outras operações</option>
                    </select>
                </div>
            </div>
        </div>
    );

    const renderCOFINSFields = () => (
        <div>
            <div class="mb-4 p-3 border rounded"><h5 style={{marginBottom: 1 + 'em'}}>Configuração do COFINS</h5>
                <div className="mb-3">
                    <label className="form-label">Alíquota</label>
                    <input
                        type="number"
                        className="form-control"
                        name="cofinsAliquota"
                        value={formData.cofinsAliquota || ''}
                        onChange={handleInputChange}
                        onBlur={(e) => {
                            // Formata para 2 casas decimais ao sair do campo
                            const formattedValue = parseFloat(e.target.value).toFixed(2);
                            handleInputChange({ target: { name: e.target.name, value: formattedValue } });
                        }}
                        step="0.01"
                        min="0"
                        max="100"
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Situação Tributária <span className="text-danger">*</span></label>
                    <select
                        className="form-select"
                        name="cofinsSituation"
                        value={formData.cofinsSituation || ''}
                        onChange={handleInputChange}
                        required
                    >
                        <option value="">Selecione</option>
                        <option value="01">01 - Operação tributável cumulativo/não cumulativo</option>
                        <option value="02">02 - Operação tributável alíquota diferenciada</option>
                        <option value="03">03 - Operação tributável quantidade vendida por alíquota por unidade produto</option>
                        <option value="04">04 - Operação tributável monofásica alíquota zero</option>
                        <option value="05">05 - Operação tributável ST</option>
                        <option value="06">06 - Operação tributável alíquota zero</option>
                        <option value="07">07 - Operação isenta de contribuição</option>
                        <option value="08">08 - Operação sem incidência de contribuição</option>
                        <option value="09">09 - Operação com suspensão de contribuição</option>
                        <option value="49">49 - Outras operações de saída</option>
                        <option value="50">50 - Operação direito crédito vinculada exclusivamente receita tributada mercado interno</option>
                        <option value="51">51 - Operação direito crédito vinculada exclusivamente receita não tributada mercado interno</option>
                        <option value="52">52 - Operação direito crédito vinculada exclusivamente receita exportação</option>
                        <option value="53">53 - Operação direito crédito vinculada receitas tributada e não tributada mercado interno</option>
                        <option value="54">54 - Operação direito crédito vinculada receitas tributadas no mercado interno exportação</option>
                        <option value="55">55 - Operação direito crédito vinculada receitas não tributada no mercado interno exportação</option>
                        <option value="56">56 - Operação direito crédito vinculada receitas tributadas e não tributadas mercado interno exportação</option>
                        <option value="60">60 - Crédito presumido operação aquisição vinculada exclusivamente receita tributada mercado interno</option>
                        <option value="61">61 - Crédito presumido operação aquisição vinculada exclusivamente à receita não tributada mercado interno</option>
                        <option value="62">62 - Crédito presumido operação aquisição vinculada exclusivamente receita exportação</option>
                        <option value="63">63 - Crédito presumido operação aquisição vinculada receitas tributadas mercado interno</option>
                        <option value="64">64 - Crédito presumido operação aquisição vinculada receitas tributadas mercado interno exportação</option>
                        <option value="65">65 - Crédito presumido operação aquisição vinculada receitas não tributadas mercado interno exportação</option>
                        <option value="66">66 - Crédito presumido operação aquisição vinculada receitas tributadas e não tributadas mercado interno exportação</option>
                        <option value="67">67 - Crédito presumido outras operações</option>
                        <option value="70">70 - Operação aquisição sem direito crédito</option>
                        <option value="71">71 - Operação aquisição com isenção</option>
                        <option value="72">72 - Operação aquisição com suspensão</option>
                        <option value="73">73 - Operação aquisição alíquota zero</option>
                        <option value="74">74 - Operação aquisição sem incidência de contribuição</option>
                        <option value="75">75 - Operação aquisição por substituição tributária</option>
                        <option value="98">98 - Outras operações de entrada</option>
                        <option value="99">99 - Outras operações</option>
                    </select>
                </div>
            </div>
        </div>
    );

    const renderIPIFields = () => (
        <div>
            <div class="mb-4 p-3 border rounded"><h5 style={{marginBottom: 1 + 'em'}}>Configuração do IPI</h5>
                <div className="mb-3">
                    <label className="form-label">Código de Enquadramento</label>
                    <input
                        type="text"
                        className="form-control"
                        name="ipiCode"
                        value={formData.ipiCode || ''}
                        onChange={handleInputChange}
                        onKeyDown={handleNumber}
                        maxLength={4}
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Alíquota</label>
                    <input
                        type="number"
                        className="form-control"
                        name="ipiAliquota"
                        value={formData.ipiAliquota || ''}
                        onChange={handleInputChange}
                        onBlur={(e) => {
                            // Formata para 2 casas decimais ao sair do campo
                            const formattedValue = parseFloat(e.target.value).toFixed(2);
                            handleInputChange({ target: { name: e.target.name, value: formattedValue } });
                        }}
                        step="0.01"
                        min="0"
                        max="100"
                    />
                </div>
            </div>
        </div>
    );

    return (
        <CSSTransition in={inProp} timeout={300} classNames="fade" appear>
            <div className="form-limited-width mt-4">
                {loading ? (
                    <div className="spinner-container">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : showForm ? (
                    <div>
                        <h2 className="mb-4">{taxRule.id ? 'Editar Regra Fiscal' : 'Nova Regra Fiscal'}</h2>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${activeTab === 'dadosGerais' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('dadosGerais')}
                                >
                                    Dados Gerais{'\u00A0'}<span className="text-danger">*</span>
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${activeTab === 'icms' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('icms')}
                                >
                                    ICMS{'\u00A0'}<span className="text-danger">*</span>
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${activeTab === 'pis' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('pis')}
                                >
                                    PIS{'\u00A0'}<span className="text-danger">*</span>
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${activeTab === 'cofins' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('cofins')}
                                >
                                    COFINS{'\u00A0'}<span className="text-danger">*</span>
                                </button>
                            </li>
                            <li className="nav-item">
                                <button
                                    className={`nav-link ${activeTab === 'ipi' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('ipi')}
                                >
                                    IPI
                                </button>
                            </li>
                        </ul>
                        <form className="mt-4">
                            {activeTab === 'dadosGerais' && renderGeneralData()}
                            {activeTab === 'icms' && renderICMSFields()}
                            {activeTab === 'pis' && renderPISFields()}
                            {activeTab === 'cofins' && renderCOFINSFields()}
                            {activeTab === 'ipi' && renderIPIFields()}

                            <button
                                type="button"
                                className="btn btn-primary me-2"
                                onClick={handleSave}
                                disabled={!isFormChanged || !areRequiredFieldsFilled()}
                            >
                                <FontAwesomeIcon icon={faSave} /> Salvar
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={handleCancel}>
                                Cancelar
                            </button>
                        </form>
                    </div>
                ) : null}
            </div>
        </CSSTransition>
    );
}

export default TaxRuleEdit;
