import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSort, faSortUp, faSortDown, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import axios from './AxiosInstance';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Paginator } from 'primereact/paginator';
import ConfirmationModal from './ConfirmationModal';
import { CSSTransition } from 'react-transition-group';

function StockLocationList() {
    const {setAlert, axiosConfigured } = useContext(AuthContext);
    const [stockLocations, setStockLocations] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [sortField, setSortField] = useState('name');
    const [sortOrder, setSortOrder] = useState(1);
    const [loading, setLoading] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [locationToDelete, setLocationToDelete] = useState(null);
    const hasFetched = useRef(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [inProp, setInProp] = useState(false);

    useEffect(() => {
        if (axiosConfigured && !hasFetched.current) {
            const state = location.state;
            if (state) {
                setSearchTerm(state.searchTerm || '');
                setPage(state.page || 0);
                setRows(state.rows || 10);
                setSortField(state.sortField || 'name');
                setSortOrder(state.sortOrder || 1);
            }
            fetchStockLocations(state?.page || page, state?.rows || rows, state?.sortField || sortField, state?.sortOrder || sortOrder, state?.searchTerm || searchTerm);
            hasFetched.current = true;
        }
    }, [axiosConfigured, location.state, page, rows, searchTerm, sortField, sortOrder]);

    const fetchStockLocations = async (page, rows, sortField, sortOrder, searchTerm) => {
        setLoading(true);
        try {
            const response = await axios.get('/api/stock-locations', {
                params: {
                    search: searchTerm,
                    page: page,
                    size: rows,
                    sort: `${sortField},${sortOrder === 1 ? 'asc' : 'desc'}`,
                },
            });
            const stockLocationData = response.data.content || [];
            setStockLocations(stockLocationData);
            setTotalRecords(response.data.totalElements || 0);
        } catch (error) {
            console.error('Erro ao buscar locais de estoque', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = () => {
        fetchStockLocations(page, rows, sortField, sortOrder, searchTerm);
    };

    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 1 ? -1 : 1;
        setSortField(field);
        setSortOrder(order);
        fetchStockLocations(page, rows, field, order, searchTerm);
    };

    const getSortIcon = (field) => {
        if (sortField === field) {
            return sortOrder === 1 ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
        }
        return <FontAwesomeIcon icon={faSort} />;
    };

    const onPageChange = (event) => {
        setPage(event.page);
        setRows(event.rows);
        fetchStockLocations(event.page, event.rows, sortField, sortOrder, searchTerm);
    };

    const handleEditStockLocation = async (stockLocation) => {
        try {
            setInProp(false);
            setTimeout(() => {
                navigate(`/stock-locations/${stockLocation.id}`, {
                    state: { stockLocation, searchTerm, page, rows, sortField, sortOrder },
                });
            }, 300);
        } catch (error) {
            console.error('Erro ao buscar local de estoque atualizado', error);
            setAlert({ type: 'danger', message: 'Erro ao carregar o local de estoque atualizado' });
        }
    };

    const handleDeleteStockLocation = async () => {
        try {
            await axios.delete(`/api/stock-locations/${locationToDelete.id}`);
            setAlert({ type: 'success', message: 'Local de estoque excluído com sucesso' });
            setShowConfirmModal(false);
            fetchStockLocations(page, rows, sortField, sortOrder, searchTerm);
        } catch (error) {
            console.error('Erro ao excluir local de estoque', error);
            setShowConfirmModal(false);
        }
    };

    const confirmDeleteStockLocation = (stockLocation) => {
        setLocationToDelete(stockLocation);
        setShowConfirmModal(true);
    };

    return (
        <CSSTransition in={inProp} timeout={300} classNames="fade" appear onEntered={() => setInProp(true)}>
            <div>
                <h2 className="mb-4">Locais de Estoque</h2>

                <div className="d-flex justify-content-between mb-4">
                    <button className="btn btn-primary" onClick={() => navigate('/stock-locations/new', { state: { searchTerm, page, rows, sortField, sortOrder } })}>
                        <span className="d-none d-md-inline">Novo Local de Estoque</span>
                        <span className="d-inline d-md-none">Novo</span>
                    </button>
                    <div className="input-group" style={{ maxWidth: '300px' }}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Pesquisar"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                        />
                        <button className="btn btn-outline-secondary btn-search" onClick={handleSearch}>
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </div>
                </div>

                {loading ? (
                    <div className="spinner-container">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : stockLocations.length > 0 ? (
                    <>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th onClick={() => handleSort('name')} className='sortable-column'>Nome {getSortIcon('name')}</th>
                                    <th onClick={() => handleSort('city.name')} className='sortable-column d-none d-lg-table-cell'>Cidade/UF {getSortIcon('city.name')}</th>
                                    <th onClick={() => handleSort('active')} className='sortable-column d-none d-md-table-cell'>Ativo {getSortIcon('active')}</th>
                                    <th className='text-end'>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {stockLocations.map((stockLocation) => (
                                    <tr key={stockLocation.id}>
                                        <td className="ellipsis">{stockLocation.name}</td>
                                        <td className="d-none d-lg-table-cell">{stockLocation.city ? `${stockLocation.city.name} / ${stockLocation.city.uf}` : 'N/A'}</td>
                                        <td className="d-none d-md-table-cell">{stockLocation.active ? 'Sim' : 'Não'}</td>
                                        <td className='text-end'>
                                            <button className="btn btn-sm border-secondary custom-btn me-2" title="Editar" onClick={() => handleEditStockLocation(stockLocation)}>
                                                <FontAwesomeIcon icon={faPencil} />
                                            </button>
                                            <button className="btn btn-sm border-secondary custom-btn me-2" title="Excluir" onClick={() => confirmDeleteStockLocation(stockLocation)}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <Paginator
                            first={page * rows}
                            rows={rows}
                            totalRecords={totalRecords}
                            rowsPerPageOptions={[10, 20, 50]}
                            onPageChange={onPageChange}
                        />
                    </>
                ) : (
                    <div className="no-data">
                        <p>Ainda não há dados por aqui, ou sua pesquisa não retornou resultados.</p>
                    </div>
                )}

                <ConfirmationModal
                    show={showConfirmModal}
                    onHide={() => setShowConfirmModal(false)}
                    onConfirm={handleDeleteStockLocation}
                    title="Confirmar Exclusão"
                    message={`Tem certeza que deseja excluir o local de estoque ${locationToDelete?.name}?`}
                />
            </div>
        </CSSTransition>
    );
}

export default StockLocationList;
