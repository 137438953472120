import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import axios from './AxiosInstance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import '../assets/styles/App.css';
import { CSSTransition } from 'react-transition-group';

function UnitMeasurementEdit() {
    const { setAlert, axiosConfigured } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        name: '',
        initials: '',
        decimals: 0,
        active: true
    });
    const [loading, setLoading] = useState(false); // Inicializando como false para controlar o estado
    const [showForm, setShowForm] = useState(false); // Estado para controlar a exibição do formulário
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const { unitMeasurement: initialUnitMeasurement, searchTerm, page, rows, sortField, sortOrder } = location.state || {};
    const [isFormChanged, setIsFormChanged] = useState(false);
    const hasFetchedData = useRef(false);
    const [unitMeasurement, setUnitMeasurement] = useState(initialUnitMeasurement || {});
    const [inProp, setInProp] = useState(false);
    const loadingTimeout = useRef(null);

    const loadUnitMeasurement = useCallback(async () => {
        try {
            const response = await axios.get(`/api/unit-measurements/${id}`);
            clearTimeout(loadingTimeout.current);
            initializeForm(response.data);
            setShowForm(true);
        } catch (error) {
            console.error('Erro ao carregar unidade de medida:', error);
            if (error.response && error.response.status === 404) {
                navigate('/unit-measurements', { state: { searchTerm, page, rows, sortField, sortOrder } });
            }
        } finally {
            setLoading(false);
        }
    }, [id, navigate, page, rows, searchTerm, sortOrder, sortField]);

    useEffect(() => {
        if (axiosConfigured && !hasFetchedData.current && id !== 'new') {
            loadingTimeout.current = setTimeout(() => {
                setLoading(true);
            }, 500);
            loadUnitMeasurement();
            hasFetchedData.current = true;
        } else if (id === 'new') {
            clearTimeout(loadingTimeout.current);
            setLoading(false);
            setShowForm(true); // Mostrar o formulário para novos itens
        }
    }, [axiosConfigured, id, loadUnitMeasurement]);

    useEffect(() => {
        setInProp(true);
    }, []);

    const initializeForm = (unitMeasurement) => {
        setUnitMeasurement(unitMeasurement);
        setFormData({
            name: unitMeasurement.name,
            initials: unitMeasurement.initials,
            decimals: unitMeasurement.decimals,
            active: unitMeasurement.active
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => {
            const isChanged = prevData[name] !== value;
            if (isChanged) {
                setIsFormChanged(true);
            }
            return { ...prevData, [name]: value };
        });
    };

    const areRequiredFieldsFilled = () => {
        const requiredFields = ['name', 'initials', 'decimals'];
        return requiredFields.every((field) => {
            const value = formData[field];
            if (typeof value === 'string') {
                return value.trim() !== '';
            } else if (field === 'decimals') {
                return value >= 0;
            } else {
                return !!value;
            }
        });
    };

    const handleSave = async () => {
        setIsFormChanged(false);

        try {
            const dataToSend = { ...formData };
    
            if (unitMeasurement.id) {
                await axios.put(`/api/unit-measurements/${unitMeasurement.id}`, dataToSend);
                setAlert({ type: 'success', message: 'Unidade de medida editada com sucesso.' });
            } else {
                await axios.post('/api/unit-measurements', dataToSend);
                setAlert({ type: 'success', message: 'Unidade de medida adicionada com sucesso.' });
            }
            setInProp(false);
            setTimeout(() => {
                navigate('/unit-measurements', { state: { searchTerm, page, rows, sortField, sortOrder } });
            }, 300);
        } catch (error) {
            setIsFormChanged(true);
            console.error('Erro ao salvar unidade de medida', error);
        }
    };
    
    const handleCancel = () => {
        setInProp(false);
        setTimeout(() => {
            navigate('/unit-measurements', { state: { searchTerm, page, rows, sortField, sortOrder } });
        }, 300);
    };

    return (
        <CSSTransition in={inProp} timeout={300} classNames="fade" appear>
            <div className="form-limited-width mt-4">
                {loading ? (
                    <div className="spinner-container">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : showForm ? (
                    <div>
                        <h2 className="mb-4">{unitMeasurement.id ? 'Editar Unidade de Medida' : 'Nova Unidade de Medida'}</h2>
                        <form>
                            <div className="mb-4 p-3 border rounded">
                                <h5 style={{marginBottom: 1 + 'em'}}>Informações Básicas</h5>
                                <div className="mb-3">
                                    <label className="form-label">Nome <span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        value={formData.name || ''}
                                        onChange={handleInputChange}
                                        required
                                        maxLength={60}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Iniciais <span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="initials"
                                        value={formData.initials || ''}
                                        onChange={handleInputChange}
                                        required
                                        maxLength={5}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Decimais <span className="text-danger">*</span></label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="decimals"
                                        value={formData.decimals}
                                        onChange={handleInputChange}
                                        min="0"
                                        max="6"
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Ativo <span className="text-danger">*</span></label>
                                    <select 
                                        className="form-select"
                                        name="active"
                                        value={formData.active}
                                        onChange={handleInputChange}>
                                        <option value={true}>Sim</option>
                                        <option value={false}>Não</option>
                                    </select>
                                </div>
                            </div>

                            <button
                                type="button"
                                className="btn btn-primary me-2"
                                onClick={handleSave}
                                disabled={!isFormChanged || !areRequiredFieldsFilled()}
                            >
                                <FontAwesomeIcon icon={faSave} /> Salvar
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={handleCancel}>
                                Cancelar
                            </button>
                        </form>
                    </div>
                ) : null}
            </div>
        </CSSTransition>
    );
}

export default UnitMeasurementEdit;
