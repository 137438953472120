import React, { useContext, useEffect, useRef, useCallback } from 'react';
import axios from './AxiosInstance';
import { AuthContext } from './AuthContext';

function Home() {
  const { logout, axiosConfigured } = useContext(AuthContext);
  const hasFetched = useRef(false); // Ref para controlar se a função verifySession já foi chamada

  const verifySession = useCallback(async () => {
    try {
      await axios.get('/api/users/verify');
    } catch (error) {
      logout(false);
    }
  }, [logout]);

  useEffect(() => {
    if (axiosConfigured && !hasFetched.current) {
      verifySession();
      hasFetched.current = true;
    }
  }, [axiosConfigured, verifySession]);

  return (
    <div>
      <h2>Dashboards</h2>
    </div>
  );
}

export default Home;
