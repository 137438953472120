import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import axios from './AxiosInstance';
import AsyncSelect2 from './AsyncSelect2'; // Importa o componente AsyncSelect2
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import '../assets/styles/App.css';
import { CSSTransition } from 'react-transition-group';

function SalesCategoryEdit() {
    const { setAlert, axiosConfigured } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        active: true,
        taxRule: null,
    });
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [selectedTaxRule, setSelectedTaxRule] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const { salesCategory: initialSalesCategory, searchTerm, page, rows, sortField, sortOrder } = location.state || {};
    const [isFormChanged, setIsFormChanged] = useState(false);
    const hasFetchedData = useRef(false);
    const [salesCategory, setSalesCategory] = useState(initialSalesCategory || {});
    const [inProp, setInProp] = useState(false);
    const loadingTimeout = useRef(null);

    const loadSalesCategory = useCallback(async () => {
        try {
            const response = await axios.get(`/api/sales-category/${id}`);
            clearTimeout(loadingTimeout.current);
            initializeForm(response.data);
            setShowForm(true);
        } catch (error) {
            console.error('Erro ao carregar categoria de vendas:', error);
            if (error.response && error.response.status === 404) {
                navigate('/sales-category', { state: { searchTerm, page, rows, sortField, sortOrder } });
            }
        } finally {
            setLoading(false);
        }
    }, [id, navigate, page, rows, searchTerm, sortField, sortOrder]);

    useEffect(() => {
        if (axiosConfigured && !hasFetchedData.current && id !== 'new') {
            loadingTimeout.current = setTimeout(() => {
                setLoading(true);
            }, 500);
            loadSalesCategory();
            hasFetchedData.current = true;
        } else if (id === 'new') {
            clearTimeout(loadingTimeout.current);
            setLoading(false);
            setShowForm(true);
        }
    }, [axiosConfigured, id, loadSalesCategory]);

    useEffect(() => {
        setInProp(true);
    }, []);

    const initializeForm = (salesCategory) => {
        setSalesCategory(salesCategory);
        setFormData({
            name: salesCategory.name,
            description: salesCategory.description,
            active: salesCategory.active,
            taxRule: salesCategory.taxRule ? salesCategory.taxRule.id : null,
        });
        setSelectedTaxRule(salesCategory.taxRule ? {
            label: salesCategory.taxRule.name,
            value: salesCategory.taxRule.id,
        } : null);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => {
            const isChanged = prevData[name] !== value;
            if (isChanged) {
                setIsFormChanged(true);
            }
            return { ...prevData, [name]: value };
        });
    };

    const handleTaxRuleChange = (selectedOption) => {
        const selectedTaxRule = selectedOption ? {
            id: selectedOption.value,
            name: selectedOption.label,
        } : null;
        
        setSelectedTaxRule(selectedOption);
        setFormData((prevData) => {
            const isChanged = prevData.taxRule !== selectedTaxRule?.id;
            if (isChanged) {
                setIsFormChanged(true);
            }
            return { ...prevData, taxRule: selectedTaxRule?.id || null };
        });
    };

    const areRequiredFieldsFilled = () => {
        const requiredFields = ['name'];
        return requiredFields.every((field) => {
            const value = formData[field];
            if (typeof value === 'string') {
                return value.trim() !== '';
            } else {
                return !!value;
            }
        });
    };

    const handleSave = async () => {
        setIsFormChanged(false);

        try {
            const dataToSend = {
                ...formData,
                taxRule: selectedTaxRule ? {
                    id: selectedTaxRule.value,
                } : null
            };
    
            if (salesCategory.id) {
                await axios.put(`/api/sales-category/${salesCategory.id}`, dataToSend);
                setAlert({ type: 'success', message: 'Categoria de venda editada com sucesso.' });
            } else {
                await axios.post('/api/sales-category', dataToSend);
                setAlert({ type: 'success', message: 'Categoria de venda adicionada com sucesso.' });
            }

            setInProp(false);
            setTimeout(() => {
                navigate('/sales-category', { state: { searchTerm, page, rows, sortField, sortOrder } });
            }, 300);
        } catch (error) {
            setIsFormChanged(true);
            console.error('Erro ao salvar categoria de vendas', error);
        }
    };
    
    const handleCancel = () => {
        setInProp(false);
        setTimeout(() => {
            navigate('/sales-category', { state: { searchTerm, page, rows, sortField, sortOrder } });
        }, 300);
    };

    return (
        <CSSTransition in={inProp} timeout={300} classNames="fade" appear>
            <div className="form-limited-width mt-4">
                {loading ? (
                    <div className="spinner-container">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : showForm ? (
                    <div>
                        <h2 className="mb-4">{salesCategory.id ? 'Editar Categoria de Venda' : 'Nova Categoria de Venda'}</h2>
                        <form>
                            <div className="mb-4 p-3 border rounded">
                                <h5 style={{marginBottom: 1 + 'em'}}>Informações Básicas</h5>
                                <div className="mb-3">
                                    <label className="form-label">Nome <span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        value={formData.name || ''}
                                        onChange={handleInputChange}
                                        required
                                        maxLength={60}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Regra fiscal</label>
                                    <AsyncSelect2
                                        url="/api/tax-rules"
                                        value={selectedTaxRule}
                                        onChange={handleTaxRuleChange}
                                        valueField="id"
                                        labelField="name"
                                        placeholder="Selecione a regra fiscal"
                                        initialOptions={selectedTaxRule ? [selectedTaxRule] : []}
                                        labelFormatter={(item) => `${item.name}`}
                                        showClear
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Descrição</label>
                                    <textarea
                                        className="form-control"
                                        name="description"
                                        value={formData.description || ''}
                                        onChange={handleInputChange}
                                        rows="4"
                                        maxLength={255}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Ativo <span className="text-danger">*</span></label>
                                    <select 
                                        className="form-select"
                                        name="active"
                                        value={formData.active}
                                        onChange={handleInputChange}>
                                        <option value={true}>Sim</option>
                                        <option value={false}>Não</option>
                                    </select>
                                </div>
                            </div>

                            <button
                                type="button"
                                className="btn btn-primary me-2"
                                onClick={handleSave}
                                disabled={!isFormChanged || !areRequiredFieldsFilled()}
                            >
                                <FontAwesomeIcon icon={faSave} /> Salvar
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={handleCancel}>
                                Cancelar
                            </button>
                        </form>
                    </div>
                ) : null}
            </div>
        </CSSTransition>
    );
}

export default SalesCategoryEdit;
