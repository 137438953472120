import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSort, faSortUp, faSortDown, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import axios from './AxiosInstance';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Paginator } from 'primereact/paginator';
import ConfirmationModal from './ConfirmationModal';
import { CSSTransition } from 'react-transition-group';

function ItemGroupList() {
    const { setAlert, axiosConfigured } = useContext(AuthContext);
    const [itemGroups, setItemGroups] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState(10);
    const [sortField, setSortField] = useState('name');
    const [sortOrder, setSortOrder] = useState(1);
    const [loading, setLoading] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [groupToDelete, setGroupToDelete] = useState(null);
    const hasFetched = useRef(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [inProp, setInProp] = useState(false);

    useEffect(() => {
        if (axiosConfigured && !hasFetched.current) {
            const state = location.state;
            if (state) {
                setSearchTerm(state.searchTerm || '');
                setPage(state.page || 0);
                setRows(state.rows || 10);
                setSortField(state.sortField || 'name');
                setSortOrder(state.sortOrder || 1);
            }
            fetchItemGroups(state?.page || page, state?.rows || rows, state?.sortField || sortField, state?.sortOrder || sortOrder, state?.searchTerm || searchTerm);
            hasFetched.current = true;
        }
    }, [axiosConfigured, location.state, page, rows, searchTerm, sortField, sortOrder]);

    const fetchItemGroups = async (page, rows, sortField, sortOrder, searchTerm) => {
        setLoading(true);
        try {
            const response = await axios.get('/api/items/groups', {
                params: {
                    search: searchTerm,
                    page: page,
                    size: rows,
                    sort: `${sortField},${sortOrder === 1 ? 'asc' : 'desc'}`,
                },
            });
            const itemGroupData = response.data.content || [];
            setItemGroups(itemGroupData);
            setTotalRecords(response.data.totalElements || 0);
        } catch (error) {
            console.error('Erro ao buscar grupos de itens', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = () => {
        fetchItemGroups(page, rows, sortField, sortOrder, searchTerm);
    };

    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 1 ? -1 : 1;
        setSortField(field);
        setSortOrder(order);
        fetchItemGroups(page, rows, field, order, searchTerm);
    };

    const getSortIcon = (field) => {
        if (sortField === field) {
            return sortOrder === 1 ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
        }
        return <FontAwesomeIcon icon={faSort} />;
    };

    const onPageChange = (event) => {
        setPage(event.page);
        setRows(event.rows);
        fetchItemGroups(event.page, event.rows, sortField, sortOrder, searchTerm);
    };

    const handleEditItemGroup = async (itemGroup) => {
        try {
            setInProp(false);
            setTimeout(() => {
                navigate(`/items/groups/${itemGroup.id}`, {
                    state: { itemGroup, searchTerm, page, rows, sortField, sortOrder },
                });
            }, 300);
        } catch (error) {
            console.error('Erro ao buscar grupo de item atualizado', error);
            setAlert({ type: 'danger', message: 'Erro ao carregar o grupo de item atualizado' });
        }
    };

    const handleDeleteItemGroup = async () => {
        try {
            await axios.delete(`/api/items/groups/${groupToDelete.id}`);
            const newPage = page > 0 && itemGroups.length === 1 ? page - 1 : page;
            setPage(newPage);
            fetchItemGroups(newPage, rows, sortField, sortOrder, searchTerm);
            setAlert({ type: 'success', message: 'Grupo de item excluído com sucesso' });
            setShowConfirmModal(false);
        } catch (error) {
            console.error('Erro ao excluir grupo de item', error);
            setShowConfirmModal(false);
        }
    };

    const confirmDeleteItemGroup = (itemGroup) => {
        setGroupToDelete(itemGroup);
        setShowConfirmModal(true);
    };

    return (
        <CSSTransition in={inProp} timeout={300} classNames="fade" appear onEntered={() => setInProp(true)}>
            <div>
                <h2 className="mb-4">Grupos de Itens</h2>

                <div className="d-flex justify-content-between mb-4">
                    <button className="btn btn-primary" onClick={() => navigate('/items/groups/new', { state: { searchTerm, page, rows, sortField, sortOrder } })}>
                        <span className="d-none d-md-inline">Novo Grupo de Itens</span>
                        <span className="d-inline d-md-none">Novo</span>
                    </button>
                    <div className="input-group" style={{ maxWidth: '300px' }}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Pesquisar"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                        />
                        <button className="btn btn-outline-secondary btn-search" onClick={handleSearch}>
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </div>
                </div>

                {loading ? (
                    <div className="spinner-container">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : itemGroups.length > 0 ? (
                    <>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th onClick={() => handleSort('name')} className='sortable-column'>Nome {getSortIcon('name')}</th>
                                    <th onClick={() => handleSort('parentGroup.name')} className='sortable-column d-none d-lg-table-cell'>Grupo Pai {getSortIcon('parentGroup.name')}</th>
                                    <th onClick={() => handleSort('active')} className='sortable-column d-none d-md-table-cell'>Ativo {getSortIcon('active')}</th>
                                    <th className='text-end'>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {itemGroups.map((itemGroup) => (
                                    <tr key={itemGroup.id}>
                                        <td className="ellipsis">{itemGroup.name}</td>
                                        <td className="d-none d-lg-table-cell">{itemGroup.parentGroup ? itemGroup.parentGroup.name : 'N/A'}</td>
                                        <td className="d-none d-md-table-cell">{itemGroup.active ? 'Sim' : 'Não'}</td>
                                        <td className='text-end'>
                                            <button className="btn btn-sm border-secondary custom-btn me-2" title="Editar" onClick={() => handleEditItemGroup(itemGroup)}>
                                                <FontAwesomeIcon icon={faPencil} />
                                            </button>
                                            <button className="btn btn-sm border-secondary custom-btn me-2" title="Excluir" onClick={() => confirmDeleteItemGroup(itemGroup)}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <Paginator
                            first={page * rows}
                            rows={rows}
                            totalRecords={totalRecords}
                            rowsPerPageOptions={[10, 20, 50]}
                            onPageChange={onPageChange}
                        />
                    </>
                ) : (
                    <div className="no-data">
                        <p>Ainda não há dados por aqui, ou sua pesquisa não retornou resultados.</p>
                    </div>
                )}

                <ConfirmationModal
                    show={showConfirmModal}
                    onHide={() => setShowConfirmModal(false)}
                    onConfirm={handleDeleteItemGroup}
                    title="Confirmar Exclusão"
                    message={`Tem certeza que deseja excluir o grupo de item ${groupToDelete?.name}?`}
                />
            </div>
        </CSSTransition>
    );
}

export default ItemGroupList;
