import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import axios from './AxiosInstance';
import AsyncSelect2 from './AsyncSelect2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import '../assets/styles/App.css';
import { CSSTransition } from 'react-transition-group';

function StockLocationEdit() {
    const { setAlert, axiosConfigured } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        name: '',
        active: true,
        city: null
    });
    const [loading, setLoading] = useState(false); // Inicializando como false para controlar o estado
    const [showForm, setShowForm] = useState(false); // Estado para controlar a exibição do formulário
    const [selectedCity, setSelectedCity] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const { stockLocation: initialStockLocation, searchTerm, page, rows, sortField, sortOrder } = location.state || {};
    const [isFormChanged, setIsFormChanged] = useState(false);
    const hasFetchedData = useRef(false);
    const [stockLocation, setStockLocation] = useState(initialStockLocation || {});
    const [inProp, setInProp] = useState(false);
    const loadingTimeout = useRef(null);

    const loadStockLocation = useCallback(async () => {
        try {
            const response = await axios.get(`/api/stock-locations/${id}`);
            clearTimeout(loadingTimeout.current);
            initializeForm(response.data);
            setShowForm(true); // Mostrar o formulário apenas quando os dados estiverem carregados
        } catch (error) {
            console.error('Erro ao carregar local de estoque:', error);
            if (error.response && error.response.status === 404) {
                navigate('/stock-locations', { state: {searchTerm, page, rows, sortField, sortOrder } });
            }
        } finally {
            setLoading(false);
        }
    }, [id, navigate, page, rows, sortField, sortOrder, searchTerm]);

    useEffect(() => {
        if (axiosConfigured && !hasFetchedData.current && id !== 'new') {
            loadingTimeout.current = setTimeout(() => {
                setLoading(true);
            }, 500);
            loadStockLocation();
            hasFetchedData.current = true;
        } else if (id === 'new') {
            clearTimeout(loadingTimeout.current);
            setLoading(false);
            setShowForm(true); // Mostrar o formulário para novos itens
        }
    }, [axiosConfigured, id, loadStockLocation]);

    useEffect(() => {
        setInProp(true);
    }, []);

    const initializeForm = (stockLocation) => {
        setStockLocation(stockLocation);
        setFormData({
            name: stockLocation.name,
            active: stockLocation.active,
            city: stockLocation.city || null
        });
        setSelectedCity(stockLocation.city ? {
            label: `${stockLocation.city.name} / ${stockLocation.city.uf}`,
            value: stockLocation.city.id,
            name: stockLocation.city.name,
            province: stockLocation.city.uf
        } : null);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => {
            const isChanged = prevData[name] !== value;
            if (isChanged) {
                setIsFormChanged(true);
            }
            return { ...prevData, [name]: value };
        });
    };

    const handleCityChange = (selectedOption) => {
        const selectedCity = selectedOption ? {
            id: selectedOption.value,
            name: selectedOption.name,
            uf: selectedOption.province
        } : null;
        
        setSelectedCity(selectedOption);
        setFormData((prevData) => {
            const isChanged = prevData.city !== selectedCity;
            if (isChanged) {
                setIsFormChanged(true);
            }
            return { ...prevData, city: selectedCity };
        });
    };

    const areRequiredFieldsFilled = () => {
        const requiredFields = ['name', 'active'];
        return requiredFields.every((field) => {
            const value = formData[field];
            if (typeof value === 'string') {
                return value.trim() !== '';
            } else if (field === 'active') {
                return value !== undefined;
            } else {
                return !!value;
            }
        });
    };

    const handleSave = async () => {
        setIsFormChanged(false);

        try {
            const dataToSend = {
                ...formData,
                city: selectedCity ? {
                    id: selectedCity.value,
                    name: selectedCity.name,
                    uf: selectedCity.province
                } : null
            };
    
            if (stockLocation.id) {
                await axios.put(`/api/stock-locations/${stockLocation.id}`, dataToSend);
                setAlert({ type: 'success', message: 'Local de estoque editado com sucesso.' });
            } else {
                await axios.post('/api/stock-locations', dataToSend);
                setAlert({ type: 'success', message: 'Local de estoque adicionado com sucesso.' });
            }
            setInProp(false);
            setTimeout(() => {
                navigate('/stock-locations', { state: { searchTerm, page, rows, sortField, sortOrder } });
            }, 300);
        } catch (error) {
            setIsFormChanged(true);
            console.error('Erro ao salvar local de estoque', error);
        }
    };
    
    const handleCancel = () => {
        setInProp(false);
        setTimeout(() => {
            navigate('/stock-locations', { state: { searchTerm, page, rows, sortField, sortOrder } });
        }, 300);
    };

    return (
        <CSSTransition in={inProp} timeout={300} classNames="fade" appear>
            <div className="form-limited-width mt-4">
                {loading ? (
                    <div className="spinner-container">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : showForm ? (
                    <div>
                        <h2 className="mb-4">{stockLocation.id ? 'Editar Local de Estoque' : 'Novo Local de Estoque'}</h2>
                        <form>
                            <div className="mb-4 p-3 border rounded">
                                <h5 style={{marginBottom: 1 + 'em'}}>Informações Básicas</h5>
                                <div className="mb-3">
                                    <label className="form-label">Nome <span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        value={formData.name || ''}
                                        onChange={handleInputChange}
                                        required
                                        maxLength={60}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Cidade</label>
                                    <AsyncSelect2
                                        url="/api/cities"
                                        value={selectedCity}
                                        onChange={handleCityChange}
                                        valueField="ibgeCode"
                                        labelField="name"
                                        placeholder="Selecione a cidade"
                                        initialOptions={selectedCity ? [selectedCity] : []}
                                        labelFormatter={(item) => `${item.name} / ${item.province.uf}`}
                                        showClear
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Ativo <span className="text-danger">*</span></label>
                                    <select 
                                        className="form-select"
                                        name="active"
                                        value={formData.active}
                                        onChange={handleInputChange}>
                                        <option value={true}>Sim</option>
                                        <option value={false}>Não</option>
                                    </select>
                                </div>
                            </div>

                            <button
                                type="button"
                                className="btn btn-primary me-2"
                                onClick={handleSave}
                                disabled={!isFormChanged || !areRequiredFieldsFilled()}
                            >
                                <FontAwesomeIcon icon={faSave} /> Salvar
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={handleCancel}>
                                Cancelar
                            </button>
                        </form>
                    </div>
                ) : null}
            </div>
        </CSSTransition>
    );
}

export default StockLocationEdit;
